<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-12 mt-3 mb-4 text-center">
            <h1>Administrador</h1>
        </div>
        <div class=" col-md-6 mb-4 text-center">
            <div class="card">
                <div class="card-body">
                    <img src="../../../../assets/images/ico-canalizar.png" width="50">
                    <h5 class="card-title">Test sin canalizar</h5>
                    <!-- <h6 class="card-subtitle mb-2 text-muted"></h6> -->
                    <p class="card-text">Podras Canalizar el test a un psicólogo</p>
                    <a [routerLink]="['/administrador/testsporasignar']" class="card-link btn btn-primary">ver más</a>
                </div>
            </div>
        </div>
        <div class=" col-md-6 mb-4 text-center" *ngIf="this.show">    
            <div class="card">
                <div class="card-body">
                <img src="../../../../assets/images/ico-historico.png" width="50">
                    <h5 class="card-title">Test Histórico</h5>
                    <!-- <h6 class="card-subtitle mb-2 text-muted">Visualizar las solicitudes</h6> -->
                    <p class="card-text">Podras revisar todos los test ya canalizados</p>
                    <a  [routerLink]="['/administrador/testhistorico']" class="card-link btn btn-primary">ver más</a>
                </div>
            </div>
        </div>
        <div class=" col-md-6 mb-4 text-center">
            <div class="card">
                <div class="card-body">
                    <img src="../../../../assets/images/ico-comentarios.png" width="50">
                    <h5 class="card-title">Comentarios</h5>
                    <!-- <h6 class="card-subtitle mb-2 text-muted">Agrega</h6> -->
                    <p class="card-text">Podras ver los comentarios y votos de las notas</p>
                    <a [routerLink]="['/administrador/votos']" class="card-link btn btn-primary">ver más</a>
                </div>
            </div>
        </div>
        <div class=" col-md-6 mb-4 text-center">
            <div class="card">
                <div class="card-body">
                    <img src="../../../../assets/images/ico-talleres.jpg" width="50">
                    <h5 class="card-title">Talleres</h5>
                    <!-- <h6 class="card-subtitle mb-2 text-muted">Agrega</h6> -->
                    <p class="card-text">Podrás ver los usuarios suscritos a los talleres</p>
                    <a [routerLink]="['/administrador/talleres']" class="card-link btn btn-primary">ver más</a>
                </div>
            </div>
        </div>
    </div>
</div>