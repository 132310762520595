<section style="background: #F5F8FA">
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5 text-center mb-4">
                <h2 class="titulo-1 pb-4">Cancelar inscripcion a taller</h2>
                <p>Si deseas cancelar tu participación te pediría nos envíes un mensaje, es un grupo limitado y alguien más podría sumarse en tú lugar. </p>
                <p>¡Gracias por tu confianza!</p>
            </div>
            <div class="col-md-6 offset-md-3 mb-5 pb-5">
                <form #fcancela="ngForm"  ngNativeValidate (ngSubmit)="cancelar(fcancela)"class="formulario-cancel">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label for="inputNombre">Nombre</label>
                            <input ngModel name="nombre" type="text" class="form-control" id="inputNombre" required>
                        </div>
                        <div class="form-group col-12">
                            <label for="inputNombreTaller">Nombre del taller</label>
                            <select ngModel class="custom-select" name="evento" required>    
                                <option selected value="">Selecciona...</option>
                                <option *ngFor="let webinar of webinars" value="{{ webinar.tema }}"> {{ webinar.fecha}} | {{ webinar.tema }}</option>
                            </select>
                        </div>
                        <div class="form-group col-12">
                            <label for="inputEmail4">Email</label>
                            <input ngModel name="email" type="text" class="form-control" id="inputEmail4" required>
                        </div>
                        
                        
                        
                        <div class="form-group">
                            <div class="form-check">
                                <input ngModel name="acepto" class="form-check-input" type="checkbox" id="checkAcepto"
                                    required>
                                <label class="form-check-label" for="checkAcepto">
                                    Acepto y estoy de acuerdo con el <a
                                        href="https://www.simisae.com.mx/aviso-legal">aviso legal</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button class="btn-main btn-uno" style="margin: 0 auto 20px;" type="submit"  [disabled]="loading">
                        <span *ngIf="!loading; else x">Cancelar</span>
                        <ng-template #x>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Enviando...
                        </ng-template>
                    </button>
                    <div  *ngIf="success" class="alert alert-success text-center mt-2" role="alert">
                        ¡Se ha cancelado tu inscripción, muchas gracias!
                    </div>
                    <div  *ngIf="danger" class="alert alert-danger text-center mt-2" role="alert">
                        ¡Error, verifica tus datos!
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>