import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { SimisaeService } from "src/app/services/simisae.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  Usuario: any;
  Password: any;
  loading = false

  constructor(
    private _simiSAE: SimisaeService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    this.loading = true;
    const formulario = {
			Usuario: form.value.Usuario,
      Password: form.value.Password
		}
    this._simiSAE.login(formulario).subscribe((resp: any) => {
      this.loading = false;
      sessionStorage.setItem('token', resp.token);
      sessionStorage.setItem('loggedInAdmin', "1");
      sessionStorage.setItem('username', resp.username);
      
      form.reset();
      this.router.navigate(['/administrador/gestor']);
    },
    e => {
      //this.isLoading = false;
    }
    );
  }


}
