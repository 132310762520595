<div [ngClass]="{'main-banner': supportweb === true, 'main-banner-no-web' : supportweb != true }">
    <div class="container text-center">
        <h1 class="titulo-banner">SIMISAE<br><small>Centro SIMI de Salud Emocional</small></h1>
        <br>
        <carousel [noPause]="false">
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> se alcanza cuándo entendemos que lo único que controlamos son nuestras decisiones, no las de los demás
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> es pensar en ayudar, no importa si es una persona o mil, el número no importa
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> es saber que has ayudado a alguien a crecer. Puede ser en lo emocional, espiritual, laboral o económico
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;"></strong> Alguna vez te has preguntado ¿Cuántas personas te recordaran con amor y alegría cuándo ya no estés en este mundo?
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> implica saber y aceptar que todo tiene un tiempo
                </p><br>
            </slide>
        </carousel>
        <button class="btn-main btn-uno" routerLink="/consejos-del-dr-simi">Atrévete a ser feliz</button>
    </div>
</div>
<div class="container pb-5 seccion1">
    <div class="row">
        <div class="col-lg-4 col-md-6">
            <picture>
                <source type="image/webp" srcset="assets/images/serFelizSiSePuede.webp">
                <source type="image/jpeg" srcset="assets/images/serFelizSiSePuede.jpg">
                <img src="assets/images/serFelizSiSePuede.jpg" alt="SIMISAE" class="img-victor-gonzalez">
            </picture>
        </div>
        <div class="col-lg-4 col-md-6">
            <h1 class="titulo-consejos pb-2 ">10 consejos del<br><span>Dr. Simi para ser feliz</span></h1>
            <p class="text-center pb-2"> La felicidad se percibe como algo inalcanzable, como un sueño y esto no es así.
                La
                felicidad
                es la satisfacción que tengas de tu propia vida, logros, metas, amores, encuentros y deseos. </p>
            <a class="btn-main btn-uno" routerLink="/consejos-del-dr-simi"> Haz clic</a>
        </div>
        <div class="col-lg-4 ">
            <div class="webinars">
                
                <div class="text-webinar">
                    <p style="margin-bottom:6px"><img src="../../../assets/images/icono-calendario.svg" width="20"> ¡Únete a nuestros</p>
                    <h2>Webinars!</h2>
                    <p>Y hablemos de los temas más relevantes sobre salud emocional.</p>
                    <br>
                    <a class="btn-main btn-uno" routerLink="/webinars">¡Inscríbete!</a>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="section-asesorias">
    <div class="container">
        <div class="row">
            
            <div class="col-md-4 text-center asesorias-item">
                <a href="tel:8009116666">
                    <div class="asesorias-item-icon">
                        <img src="assets/images/ico-tel-2.svg" alt="icono teléfono">
                    </div>
                    <h2 class="titulo-2">Ayuda Psicológica Gratuita por Teléfono</h2>
                    <p>800 911 32 32. <br>24 Hrs. - 365 días.</p>
                </a>
            </div>
            <div class="col-md-4 text-center asesorias-item">
                <a class="chat-btn" (click)=toggleDisplay()>
                    <div class="asesorias-item-icon">
                        <img src="assets/images/ico-chat-2.svg" alt="icono chat">
                    </div>
                    <h2 class="titulo-2 ">Ayuda Psicológica Gratuita por Chat</h2>
                    <p class="">24 Hrs. - 365 días.</p>

                </a>
            </div>
            <div class="col-md-4 text-center asesorias-item">
                <a href="mailto:centrodiagnostico@simisae.com.mx">
                    <div class="asesorias-item-icon">
                        <img src="assets/images/ico-mail-2.svg" alt="icono mail">
                    </div>
                    <h2 class="titulo-2">Ayuda Psicológica Gratuita por Email</h2>
                    <p>centrodiagnostico@simisae.com.mx</p>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-programas">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center pb-4">
                <h3 class="titulo-1">Disfruta de nuestros programas</h3>
            </div>
            <div *ngFor="let video of videos" class="col-md-3 col-sm-6 col-6">
                <a [href]="sanitize(video.url)" class="programas-item" target="_blank">
                    <div class="programas-item-img">
                        <img [lazyLoad]="'../assets/images/programa/' + video.thumb"  [useSrcset]="true" alt="">
                        <img class="ico-play" src="assets/images/ico-play.svg">
                    </div>
                    <h4 class="titulo-3 pt-1">{{ video.nombre }}</h4>
                    <p>{{ video.cuando }}</p>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-newsletter" [ngClass]="{'section-newsletter': supportweb === true, '  section-newsletter-no-web' : supportweb != true }">
    <div class="container container-test">
        <div class="row">
            <div class="col-md-8 offset-md-4 col-lg-7 offset-lg-5 text-white">
                <h3 class="titulo-1 text-white">CUESTIONARIOS</h3>
                <p>En el siguiente listado encontrarás varias pruebas, las cuales no tienen un fin
                    diagnóstico, pero te ayudarán a arrojar luces sobre la existencia de un eventual problema
                    psicológico, de modo de orientarte a buscar ayuda cuando sea necesario. No esperes más. </p>
                <div class="row home-test-list mb-3">
                    <div class="col-6">
                        <ul>
                            <li><a routerLink="/tests/depresion"> Depresión </a></li>
                            <li><a routerLink="/tests/consumo-de-sustancias-drogadiccion"> Drogadicción </a></li>
                            <li><a routerLink="/tests/codependencia"> Codependencia </a></li>
                            <li><a routerLink="/tests/alcoholismo"> Alcoholismo </a></li>
                            <li><a routerLink="/tests/trastorno-de-personalidad-narcisista"> Personalidad narcisista
                                </a></li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <ul>
                            <li><a routerLink="/tests/trastorno-vaginismo"> Vaginismo</a></li>
                            <li><a routerLink="/tests/anorexia"> Anorexia </a></li>
                            <li><a routerLink="/tests/trastornos-de-ansiedad"> Trastornos de ansiedad </a></li>
                            <li><a routerLink="/tests/trastorno-bipolar-de-la-personalidad"> Trastorno bipolar</a></li>
                            <li><a routerLink="/tests/estres"> Estrés </a></li>
                        </ul>
                    </div>
                </div>
                <button class="btn-main btn-uno" routerLink="/tests">Ver más</button>
            </div>
        </div>
    </div>
</section>
<section class="section-articulos">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center pb-4">
                <h3 class="titulo-1">Infórmate</h3>
            </div>
            <div *ngFor="let nota of notas" class="col-md-4 ">
                <div class="row articulo-item">
                   
                    <div class="col-md-12 col-5">
                         <!--<div class="articulo-item-img " [style.background]="'url(../'+ nota.thumb +')'"></div> -->
                        <img src="../{{ nota.thumb }} " class="img-fluid">
                    </div>
                   
                    <div class="articulo-item-text col-md-12 col-7">
                        <h4 class="titulo-3 pt-1">{{ nota.titulo }}</h4>
                        <small>{{ nota.categoria }}</small>
                        <p> {{ nota.intro }} </p>
                        <button class="btn-main btn-uno" (click)="getNotaUrl(nota)">Ver nota</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-drSimi">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <img class="frase" src="assets/images/frase.svg">
            </div>
            <!--
            <div class="col-md-6">
                <img class="dr-simi" src="assets/images/Simi-CNDEE.gif" alt="Dr Simi psicológo">
            </div>
            -->
        </div>
    </div>
</section>
<div *ngIf="showBotton" class="show-chat">
    <div class="bg-black"></div>
    <div class="flecha-chat">
        <div class="indicador-chat">
        </div>
    </div>

</div>

<div class="seudomodal" *ngIf="edited" >
    <div class="modaltest">
        <div>
            <button type="button" class="destroy" (click)="close()">
                <span >&times;</span>
            </button>
        </div>
        <div class="col-12 mx-auto text-center imgcont"> 
            <picture>
                <source type="image/webp" srcset="assets/images/banner-nuevo-sitio.webp">
                <source type="image/jpeg" srcset="assets/images/banner-nuevo-sitio.jpg">
                <img src="assets/images/banner-nuevo-sitio.jpg" alt="SIMISAE" (click)="gopoup()" class="img-fluid  popup">
            </picture>
        </div>
    </div>      
</div>