<section class="section-title" style="background:url(../assets/images/articulos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Infórmate </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a>Infórmate</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row mb-5">
        <div class="col-12 categorias mt-4 mb-3">
            <p>CATEGORÍAS: </p>
            <!-- <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-dark dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Selecciona una categoría <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li *ngFor=" let categoria of categorias" (click)="filtrarCategorias('Salud emocional')"
                        role="menuitem"><span class="dropdown-item"> {{ categoria }} </span></li>

                </ul>
            </div> -->
            <div class="form-group categorias-select">
                <select ngModel="ciudad" name="ciudadId" (ngModelChange)="filtrarCategorias($event)" class="form-control" required>
                    <option [ngValue]="" selected>Todas las categorías</option>
                    <option *ngFor="let categoria of categorias" [ngValue]="categoria">  {{categoria}} </option>
                </select>
            </div>
        </div>
        <div *ngFor="let nota of notas | paginate: {itemsPerPage: 6, currentPage: pageActual}" class="col-md-4 ">
            <div class="row articulo-item">
                <div class="col-md-12 col-5">
                    <!--<div class="articulo-item-img " [style.background]="'url(../'+ nota.thumb +')'"></div>-->
                    <img src="../{{ nota.thumb }} " class="img-fluid">
                </div>
                <div class="articulo-item-text col-md-12 col-7 pb-5">
                    <h4 class="titulo-3 pt-1">{{ nota.titulo }}</h4>
                    <small>{{ nota.categoria | uppercase }}</small>
                    <p>{{ nota.intro }}</p>
                    <a class="btn-main btn-uno" (click)="getNotaUrl(nota)" style="cursor:pointer">Ver nota</a>
                </div>
            </div>
        </div>

        <div class="col-12 text-center" style="display: flex;">
            <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true" style="margin: auto;"> </pagination-controls>
        </div>
    </div>
</div>