<header>
    <div class="container">
        <div class="row">
            <div class="col-12 text-right" style="padding: 0;">
                <h2 style="font-size: inherit; display: inline;">AYUDA PSICOLÓGICA GRATUITA </h2>
                <a href="tel:8009113232"><strong> 800 911 32 32</strong></a>
                <a href="https://www.facebook.com/SIMISAE" target="_blank">
                    <img src="assets/images/icoFace.svg" /> 
                </a>
                <a href="https://twitter.com/simisaemx" target="_blank">
                    <img src="assets/images/icoTwitter.svg" />
                </a>
                <a href="https://www.youtube.com/channel/UCmvExLVJA7dCSeurwVJN1CA" target="_blank">
                    <img src="assets/images/icoYoutube.svg" />
                </a>

            </div>
        </div>
    </div>
</header>

<nav class="navbar navbar-expand-lg navbar-light sticky-top" *ngIf="!loggedInAdmin">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/logo-simisae.png" />
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }">
            <ul class="navbar-nav ml-auto">
                <!--
                <li class="nav-item ">
                    <a class="nav-link" routerLink="/" (click)="toggleNavbar()"><span
                            data-hover="Inicio">Inicio</span></a>
                </li>
                -->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/quienes-somos" (click)="toggleNavbar()"><span
                            data-hover="Quiénes somos&nbsp;">Quiénes
                            somos&nbsp;</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/talleres" (click)="toggleNavbar()"><span
                            data-hover="Talleres&nbsp;">Talleres&nbsp;</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/SIMIAPSE" (click)="toggleNavbar()"><span
                            data-hover="SIMIAPSE&nbsp;">SIMIAPSE&nbsp;</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/programacion" (click)="toggleNavbar()"><span
                            data-hover="Simichat &nbsp;">Simichat
                            &nbsp;</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/aprende-mas" (click)="toggleNavbar()"><span
                            data-hover="Aprende más">Aprende más</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/conocete" (click)="toggleNavbar()"><span
                            data-hover="Conócete">Conócete</span></a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link" routerLink="/psicologos-en-linea" (click)="toggleNavbar()"><span
                            data-hover="Psicólogos en línea">Psicólogos en línea</span></a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<nav class="navbar navbar-expand-xl navbar-light sticky-top " *ngIf="loggedInAdmin">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/logo-simisae.png" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{ show: navbarOpen }">
            
            <ul class=" navbar-nav ml-auto">  
                <li class="nav-item">
                    <a class="btn btn-login " (click)="logout()" (click)="toggleNavbar()">Salir</a>
                </li>
            </ul>
        </div>
    </div>
</nav>