import { Component } from '@angular/core';
import { StreamingService } from './services/streaming.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showBanner = false
	constructor(
		private streamingService: StreamingService,
	) {
		
	}

	ngOnInit() {
		this.showBanner = this.streamingService.getStreaming()
	}
}