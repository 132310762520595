import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UbicacionesService } from 'src/app/services/ubicaciones.service';
import { MapServiceService } from 'src/app/services/map-service.service';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import * as L from 'leaflet';
// declare let L: { icon: (arg0: { iconUrl: string; iconSize: number[]; iconAnchor: number[]; shadowAnchor: number[]; popupAnchor: number[]; }) => any; map: (arg0: string) => { (): any; new(): any; setView: { (arg0: number[], arg1: number): any; new(): any; }; }; tileLayer: (arg0: string, arg1: { attribution: string; }) => { (): any; new(): any; addTo: { (arg0: any): void; new(): any; }; }; marker: (arg0: any[], arg1: { icon: any; }) => { (): any; new(): any; addTo: { (arg0: any): { (): any; new(): any; bindPopup: { (arg0: string): { (): any; new(): any; getCenter: { (): any; new(): any; }; }; new(): any; }; }; new(): any; }; }; circle: (arg0: number[], arg1: { color: string; fillColor: string; fillOpacity: number; radius: number; }) => { (): any; new(): any; addTo: { (arg0: any): void; new(): any; }; }; };

@Component({
  selector: 'app-simiapse',
  templateUrl: './simiapse.component.html',
  styleUrls: ['./simiapse.component.css']
})
export class SIMIAPSEComponent implements AfterViewInit {
  private subscription: Subscription = new Subscription();
  estadoId: any;
  estadoIdg: any;
  ciudadId: any;
  // procedimientos: any;
  estados: any;
  ciudades: any;
  consultorios: any;
  munnombre: any;
  dont = false;
  okmun = false;
  espec= false;
  dataesp : any;
  geopotitonok= false;
  activo : any;

  //latLng = [23.6345005, -102.5527878];
  lat =23.6345005;
  lng=-102.5527878;
  hidden = false;
  isIE = false;
  innerHeight!: number;
  mapHeight: any;
  mapStyle: any;
  selectedUnidad: any;
  map: any;
  sub: any;
  page: any;
  icon: any;
  all = true;
  isLocated = false;
  markers: any;
  markerarr= new Array();
  zoom: number = 0;
  unidades: any;
  composer: any;
  theMarker: any;
  showpro=false;
  icons: any;
  latitudup: any;
  latituddow: any;
  longitudup: any;
  longituddow: any;

  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;
  public L:any;
  
  constructor(private _ubicacionesService: UbicacionesService,public MapService:MapServiceService,@Inject(PLATFORM_ID) public platformId: Object) { 
    // this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    //   this.RouteText = event;
    //   this.getSEO(event.url)
    // });
    if (isPlatformBrowser(platformId)) {
      this.innerHeight = window.innerHeight;
  
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
        window.navigator.userAgent
      );
  
      if (isIEOrEdge) {
        this.mapHeight = this.innerHeight - 100;
        this.isIE = true;
        this.mapStyle = {
          'background-color': 'grey',
          'min-height': this.mapHeight + 'px',
          'max-height': this.mapHeight + 'px'
        };
      }
    }
    this.zoom = 5;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerHeight = window.innerHeight;
  }

  ngAfterViewInit():void {
    this.getEstados();
    this.gomap();
  }
  ngOnInit(): void {
    this.L = this.MapService.L;
  }

  gomap() {
  
    this.icon = this.L.icon({
      iconUrl: 'assets/images/icons/location_simiapse.png',
      iconSize: [35, 35], // size of the icon
      iconAnchor: [16, 38], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62], // the same for the shadow
      popupAnchor: [3, -50] // point from which the popup should open relative to the iconAnchor
    });

    this.icons = this.L.icon({
      iconUrl: 'assets/images/icons/location_usuario.png',
      iconSize: [35, 35], // size of the icon
      iconAnchor: [16, 38], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62], // the same for the shadow
      popupAnchor: [3, -50] // point from which the popup should open relative to the iconAnchor
    });

    this.map = this.L.map('mapnu').setView([23.6345005, -102.5527878], this.zoom);

   this.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.setPosition();
  }

  geocodeAddress() {
    this.setPosition();
  }

  setView(lat: number, lng: number) {
    this.zoom = 17;
    //this.latLng = [lat, lng];
    this.map.setView(this.lat, this.lng, this.zoom);
    if (this.theMarker != undefined) {
      this.map.removeLayer(this.theMarker);
      
    };
    this.geopotitonok = true;
    this.theMarker = this.L.marker([lat, lng], { icon: this.icon }).addTo(this.map);
  }

  capitalizeFirstLetter(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLocaleLowerCase();     
    }
    return splitStr.join(' '); 
  
  }

  setPosition() {
    if (!this.isLocated) {
      this.isLocated = true;
      navigator.geolocation.getCurrentPosition(
        e => {
          
          this.lat= e.coords.latitude;
          this.lng= e.coords.longitude;
          this.latitudup = e.coords.latitude-(0.01);
          this.latituddow = e.coords.latitude+(0.01);
          this.longitudup = e.coords.longitude-(0.01);
          this.longituddow = e.coords.longitude+(0.01);
              
          this.subscription.add(
            this._ubicacionesService.getPosicionSIMIAPSE(this.latitudup,this.latituddow, this.longitudup, this.longituddow).subscribe((resp: any) => {
              if(resp.length > 0){
                this.okmun = true;
                this.dont = false;
                this.zoom = 17;
                resp.forEach((element: { latitud: any; longitud: any; unidad: any; calle: any; numeroExterior: any;  numeroInterior: any; codigoPostal: any; colonia: any;}) => {
        
                  if(element.numeroExterior.length == 0){element.numeroExterior="S/N";}
                  if(element.numeroInterior.length == 0){element.numeroInterior="S/N";}
                  element.calle = this.capitalizeFirstLetter(element.calle);
                  element.colonia = this.capitalizeFirstLetter(element.colonia);
                  this.markers = this.L.marker([element.latitud, element.longitud], { icon: this.icon })
                    .addTo(this.map)
                    .bindPopup(`       
                      <p>Calle: ${element.calle}</p>
                      <p>Número Exterior: ${element.numeroExterior}</p>
                      <p>Número Interior: ${element.numeroInterior}</p>
                      <p>Código Postal: ${element.codigoPostal}</p>
                      <p>Colonia: ${element.colonia}</p>
                    `);
                  
                  this.markerarr.push(this.markers)
                });
              }else{
                this.dont = true;
                this.okmun = false;
                this.espec = false;
              } 
              
            })
          );
          
          this.map.flyTo([this.lat, this.lng], 15);
          this.L.circle([this.lat, this.lng], {
            color: '#82b1ff',
            fillColor: '#90caf9',
            fillOpacity: 0.4,
            radius: 900
          }).addTo(this.map);
          this.theMarker =  this.L.marker([e.coords.latitude, e.coords.longitude], { icon: this.icons }).addTo(this.map);
          this.geopotitonok = true;

        },
        error => {
          if (error.code === 1) {
            console.warn('Bloqueo el GeoLocation');
            this.hidden = true;
            this.geopotitonok = false;
          }
        }
      );
    } else {
      //this.map.setView(this.currentCoords, 14);
    }
  }

  getEstados() {
    this.subscription.add(
      this._ubicacionesService.getEstadosSIMIAPSE().subscribe((resp: any) => {
        this.estados = resp;
        
      })
    );
  }

  getCiudades(estadoId: Event) {
    this.estadoIdg = estadoId;
    this.dont = false;
    this.espec = false;
    this.showpro= false;
    this.okmun = false;
    this.geopotitonok = false;
    this.subscription.add(
      this._ubicacionesService.getCiudadesSIMIAPSE(estadoId).subscribe((resp: any) => {
        this.ciudades = resp;
        
      })
    );
}

  selectCiudad(event: any) {
    this.geopotitonok = false;
    this.dont = false;
    this.espec = false;
    this.okmun = false;
    this.showpro= false;
    this.selectSucursal(event)
  }

  selectSucursal(sucursal: any) {
    this.munnombre = sucursal.nombre;
    this.consultorios='';
    this.subscription.add(
      this._ubicacionesService.getSucursalesSIMIAPSE(sucursal.idMunicipio).subscribe((resp: any) => {
        if(resp.length > 0){
          if(this.markerarr){
            for(var i=0;i<this.markerarr.length; i++) {
              this.map.removeLayer(this.markerarr[i]);
            }
          }
          this.showpro=true;
          this.geopotitonok = true;
          this.consultorios = resp;
          resp.reverse();
          resp.forEach((element: { latitud: any; longitud: any; unidad: any; calle: any; numeroExterior: any;  numeroInterior: any; codigoPostal: any; colonia: any;}) => {
        
            if(element.numeroExterior.length == 0){element.numeroExterior="S/N";}
            if(element.numeroInterior.length == 0){element.numeroInterior="S/N";}
            element.calle = this.capitalizeFirstLetter(element.calle);
            element.colonia = this.capitalizeFirstLetter(element.colonia);
            this.markers = this.L.marker([element.latitud, element.longitud], { icon: this.icon })
              .addTo(this.map)
              .bindPopup(`       
                <p>Calle: ${element.calle}</p>
                <p>Número Exterior: ${element.numeroExterior}</p>
                <p>Número Interior: ${element.numeroInterior}</p>
                <p>Código Postal: ${element.codigoPostal}</p>
                <p>Colonia: ${element.colonia}</p>
              `);
            
            this.markerarr.push(this.markers)
            this.map.flyTo([element.latitud, element.longitud], 13);
          });
        
          // this.procedimientos = [];
          // this.subscription.add(
          //   this._ubicacionesService.getProcedimientosNutricional(this.estadoIdg, this.munnombre).subscribe((resp: any) => {
              
          //     resp[0].tecnica = 'CONSULTA NUTRICIONAL'
          //     this.procedimientos = resp;
          //   })
          // );
          
        }else{
          this.dont = true;
          this.okmun = false;
          this.espec = false;
        } 
        
      })
    );
  }
  public clearInput(e:Event):void{
    this.ciudades = null;
  }


}
