import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimisaeService {

  constructor(private http: HttpClient) { }

  postnuevapostulacion(paciente: any, length: any){
    return this.http.post(`https://simisae.com.mx/webapi/simisae/api/values/registro?id=${length}`, paciente, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  postvoto(voto: any){
    return this.http.post(`https://simisae.com.mx/webapi/simisae/api/values/voto`, voto, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  login(voto: any){
    return this.http.post(`https://simisae.com.mx/webapi/simisae/api/login`, voto, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  postWebinar(voto: any){
    //`https://localhost:7185/api/Webinars` `https://mividaeslucha.com/ApiSimisae/api/Webinars`
     return this.http.post(`https://mividaeslucha.com/Simisae23/api/Webinars`, voto, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
   }
 
   deleteWebinar(email: any, evento: any){
     return this.http.put(`https://mividaeslucha.com/Simisae23/api/Webinars?email=${email}&evento=${evento}`, {
     })
   }

  GetSinEvaluar(token: any){
    return this.http.get(`https://simisae.com.mx/webapi/simisae/api/values`, {
      headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Bearer ${token}`
			}
    });
  }

  GetPsicologos(token: any){
    return this.http.get(`https://simisae.com.mx/webapi/simisae/api/values/psicologos`, {
      headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Bearer ${token}`
			}
    });
  }

  GetHistorico(token: any, FechaInicial: any, FechaFinal: any){
    return this.http.get(`https://simisae.com.mx/webapi/simisae/api/values/histociotest?FechaInicial=${FechaInicial}&FechaFinal=${FechaFinal}`, {
      headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Bearer ${token}`
			}
    });
  }

  GetVotos(token: any){
    return this.http.get(`https://simisae.com.mx/webapi/simisae/api/values/voto`, {
      headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Bearer ${token}`
			}
    });
  }
  GetWebinars(evento: any){
    return this.http.get(`https://mividaeslucha.com/Simisae23/api/Webinars?nombbreevento=${evento}`, {
    // return this.http.get(`https://localhost:5001/api/Values/registroWebinear?nombbreevento=${evento}`, {
      headers: {
			  'Content-Type': 'application/json'
			}
    });
  }
  


  GetDetalle(token: any, id: any){
    return this.http.get(`https://simisae.com.mx/webapi/simisae/api/values/${id}`, {
      headers: {
			  'Content-Type': 'application/json',
			  'Authorization': `Bearer ${token}`
			}
    });
  }

  UpdateTest( idsend:any, receptor:any , correoreceptor:any, canalizador:any, token: any) {
    const body = {};

    const params = new HttpParams()
        .append('id', idsend)
        .append('receptor', receptor)
        .append('correoreceptor', correoreceptor)
        .append('canalizador', canalizador);
      return this.http.post(`https://simisae.com.mx/webapi/simisae/api/values/Updateprogreso/`, body, {
      // return this.http.post(`https://localhost:5001/api/values/Updateprogreso/`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      params: params
    });
  }

  DeleteTest( idsend:any, token: any) {
    const body = {id:idsend};
      return this.http.post(`https://simisae.com.mx/webapi/simisae/api/values/update/`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }
  public getEstados(): Observable<any> {
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/`);
  }

  public getCiudades(id:any): Observable<any>{
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/${id}`);
  }

  public getEstadosSIMIAPSE(): Observable<any> {
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/estadoSIMIAPSE`);
  }

  public getCiudadesSIMIAPSE(id:any): Observable<any>{
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/municipioSIMIAPSE?id=${id}`);
  }
  public getSucursalesSIMIAPSE(id:any): Observable<any>{
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultorioSIMIAPSE?id=${id}`);
  }
  public getPosicionSIMIAPSE(latitudup:any, latituddow:any, longitudup:any, longituddow:any): Observable<any>{
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultorioSIMIAPSEposition?latitudup=${latitudup}&latituddow=${latituddow}&longitudup=${longitudup}&longituddow=${longituddow}`);
  }






}
