import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private http: HttpClient) { }
  propiedades: any;

  getSEO(seccion: any) {
    this.propiedades = [];
    return this.http.get("https://simisae.com.mx/assets/data/SEO.json").pipe(
      map((resp: any) => {
        resp.forEach((element: { pagina: any; }) => {
          if (element.pagina == seccion) {
            this.propiedades = element;
          }
        });
        return this.propiedades;
      })
    );
  }
}