<section class="section-title" style="background:url(../assets/images/test-bg.jpg)">
    <div class="container">
       <div class="row title-row">
          <div class="col-md-8">
             <div class="title-page"><h1> Cuestionarios </h1></div>
          </div>
          <div class="col-md-4">
             <div class="breadcrumbs">
                <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Cuestionarios</p>
             </div>
          </div>
       </div>
    </div>
 </section>
 <div class="container mt-xl mb-5">
    <div class="row">
       <div class="col-md-6">
          <h2 class="titulo-1 pb-4">Responde con la mayor honestidad</h2>
          <p>Ponemos a tu alcance cuestionarios que han sido diseñados para identificar diversas conductas, actitudes y
             signos o síntomas que se pueden presentar en diferentes trastornos o problemáticas emocionales.<br><br>
             El objetivo de realizar dichos cuestionarios es obtener información de la situación que vives y de ti, y de
             está manera poder brindar la asesoría u orientación adecuada a nuestros usuarios y así puedan tomar acciones
             que prevengan o detengan el posible desarrollo de un problema emocional.<br><br>
             A continuación, tienes una gama de cuestionarios con diversos temas a elegir, responde aquel que consideres
             se asemeje a la situación que puedas estar viviendo. Envía tu respuesta y nuestros psicólogos te responderán
             en un lapso de 48 horas vía electrónica. Ya sea para obtener más datos de la situación o bien para
             orientarte con respecto a algún centro de atención especializado en caso de que la situación lo amerite,
             buscando en lugares cercanos a tu localidad y de acuerdo con tu presupuesto.
          </p>
       </div>
       <div class="col-md-6">
          <img src="assets/images/checkbox.jpg" alt="" width="100%">
       </div>
    </div>
 </div>
 <section style="background: #F5F8FA">
    <div class="container">
       <div class="row">
          <div class="col-12 mt-5 mb-5">
             <ul class="row test-list  pb-5">
                <li *ngFor="let test of tests" class="col-md-6 test-name" style="cursor:pointer">
                   <a (click)="getTest(test.nombre)"> <img src="assets/images/ico-test.svg"
                         alt="icono test">{{test.nombre}}</a>
                </li>
             </ul>
          </div>
       </div>
    </div>
 </section>