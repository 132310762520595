import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from "ngx-bootstrap/carousel";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { AlertModule } from "ngx-bootstrap/alert";
import { NgxPaginationModule } from "ngx-pagination";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { NgxSocialShareModule } from 'ngx-social-share';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { PixelModule } from "ngx-pixel";
import { SwiperModule } from 'swiper/angular';
import { DataTablesModule } from 'angular-datatables';
import { AccordionModule } from 'ngx-bootstrap/accordion';


// Componentes
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { VideoComponent } from './components/video/video.component';
import { ArticuloComponent } from './pages/articulo/articulo.component';
import { AvisoDePrivacidadComponent } from './pages/aviso-de-privacidad/aviso-de-privacidad.component';
import { AvisoLegalComponent } from './pages/aviso-legal/aviso-legal.component';
import { ConsejosDeDrSimiComponent } from './pages/consejos-de-dr-simi/consejos-de-dr-simi.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { InformateComponent } from './pages/informate/informate.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ProgramacionComponent } from './pages/programacion/programacion.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { TestComponent } from './pages/test/test.component';
import { TestsComponent } from './pages/tests/tests.component';
import { LoginComponent } from './pages/Administrador/login/login.component';
import { DashboardComponent } from './pages/Administrador/dashboard/dashboard.component';
import { TestdetalleComponent } from './pages/Administrador/testdetalle/testdetalle.component';
import { TestlistadoComponent } from './pages/Administrador/testlistado/testlistado.component';
import { VotosComponent } from './pages/Administrador/votos/votos.component';
import { TestshistoricoComponent } from './pages/Administrador/testshistorico/testshistorico.component';
import { Inicio2Component } from './pages/inicio2/inicio2.component';
import { WebinarsComponent } from './pages/webinars/webinars.component';
import { TalleresComponent } from './pages/Administrador/talleres/talleres.component';

import { DatePipe } from '@angular/common';
import { SIMIAPSEComponent } from './pages/ubicaciones/simiapse/simiapse.component';
import { TalleresInscripcionComponent } from './pages/talleres-inscripcion/talleres-inscripcion.component';
import { CancelaInscripcionComponent } from './pages/cancela-inscripcion/cancela-inscripcion.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    VideoComponent,
    ArticuloComponent,
    AvisoDePrivacidadComponent,
    AvisoLegalComponent,
    ConsejosDeDrSimiComponent,
    ContactoComponent,
    InformateComponent,
    InicioComponent,
    ProgramacionComponent,
    QuienesSomosComponent,
    TestComponent,
    TestsComponent,
    LoginComponent,
    DashboardComponent,
    TestdetalleComponent,
    TestlistadoComponent,
    VotosComponent,
    TestshistoricoComponent,
    Inicio2Component,
    WebinarsComponent,
    TalleresComponent,
    SIMIAPSEComponent,
    TalleresInscripcionComponent,
    CancelaInscripcionComponent,
    PreguntasFrecuentesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'simisae' }),
    LazyLoadImageModule,
    AppRoutingModule,
    DataTablesModule,
    NgxSocialShareModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    PixelModule.forRoot({ enabled: true, pixelId: '626274259667830' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SwiperModule,
    AccordionModule.forRoot()
  ],
  providers: [{provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }