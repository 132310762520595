<section class="section-title" style="background:url(../assets/images/quienes-somos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page text-white"><h1>Preguntas frecuentes</h1></div>

            </div>
            <div class="col-md-4">
                <div class="breadcrumbs ">
                    <p class="text-white"><a routerLink="/" class="text-white">Inicio &nbsp;/ &nbsp;</a> Preguntas frecuentes</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row mt-xl mb-5">
        <div class="col-12">
                
            <div class="preguntas-frecuentes">
                <h3 class="titulo-1  text-center">preguntas frecuentes</h3>
                <p class="text-center">Todas las preguntas principales relacionadas con la consulta, servicio y mucho más.</p><br><br>
                <accordion [closeOthers]="oneAtATime">
                    <accordion-group heading="¿Su servicio es confidencial?">
                        <p>Sí; al inicio de la sesión te damos a conocer nuestro aviso de privacidad lo cual te brinda la seguridad de que tus datos personales estarán protegidos; puedes consultarlo en la siguiente liga: https://simisae.com.mx/aviso-de-privacidad</p>
                        <p>Aunado a ello, solo pediremos tu nombre y edad para tener información que nos ayude a la orientación psicológica, es importante mencionar que todos nuestros psicólogos son profesionales y la secrecía es parte de nuestra ética.</p>                        
                    </accordion-group>
                    <accordion-group heading="¿El servicio de ayuda psicológica tiene algún costo?">
                        <p>No, gracias a la visión altruista de nuestro fundador, el C.P. Victor González Torres (Dr. Simi), el servicio que ofrece SIMISAE es completamente gratuito.</p>
                    </accordion-group>
                    <accordion-group heading="¿Dónde puedo encontrarlos?">
                        <p>Puedes encontrarnos en los siguientes canales de atención:</p>
                        <p>Vía telefónica y WhatsApp a nuestro número 800 911 3232</p>
                        <p>Por medio de nuestro correo electrónico <strong>centrodiagnostico@simisae.com.mx </strong></p>
                        <p>Enviándonos un mensaje a nuestro Facebook o Instagram <strong>https://www.facebook.com/SIMISAE </strong>y <strong>@simisaemx</strong></p>
                        <p>Si prefieres ser atendido de forma presencial, ponemos a tu alcance el contacto de</p>
                    </accordion-group>
                    <accordion-group heading="¿Quién puede solicitar ayuda?">
                        <p>Todo aquel que necesite el apoyo psicológico, la persona que esté atravesando por un duelo, problemas para relacionarse, adicciones, depresión, ansiedad o ideación suicida, en fin, cualquier situación que esté afectando emocionalmente a la persona.</p>
                    </accordion-group>
                    <accordion-group heading="¿Qué beneficios obtengo al utilizar sus servicios?">
                        <p>Primero tendrás un espacio de desahogo acompañado por un especialista en salud mental, podrás encontrar dentro de la asesoría psicológica opciones y formas de abordar las problemáticas utilizando tus propios recursos y aprendiendo de lo vivido para fortalecer tus habilidades, que posiblemente no hayas visto, pero están ahí.</p>
                    </accordion-group>
                    <accordion-group heading="¿Dan seguimiento a mi proceso terapéutico?">
                        <p>Sí, puedes seguir buscando al psicólogo que te atiende por primera vez para darle un seguimiento a la situación que estes viviendo, es importante que anotes el nombre del psicólogo, días y horario de trabajo, para que le sigas llamando o escribiendo.</p>
                    </accordion-group>
                    <accordion-group heading="¿Existe un límite de atenciones?">
                        <p>Sí, debido a que existe una gran cantidad de personas que viven con problemáticas relacionadas a la salud mental.</p>
                    </accordion-group>
                    <accordion-group heading="¿Cuánto tiempo dispongo para comunicarme con mi psicóloga (o)?">
                        <p>El tiempo de atención en llamada es de 15 minutos con la posibilidad de tener una segunda en el mismo momento; nuestra gran ventaja es que damos seguimiento, el psicólogo te dará su nombre, días y horarios en los que labora y le puedes seguir buscando para continuar con la atención.</p>
                        <p>En nuestros canales de Messenger y WhatsApp el tiempo de atención es de 25 minutos con la misma opción de seguimiento en otro momento. NO agendamos citas ya que el servicio está abierto a todo público, pero el psicólogo nuevamente te orientará sobre sus horarios y días de trabajo para que se le busque.</p>
                    </accordion-group>
                    <accordion-group heading="¿Por qué el tiempo de atención es tan corto?">
                        <p>Trabajamos bajo el modelo de terapia breve y con herramientas prácticas que te permitan mejorar tu salud mental y emocional, por ello y pensando que un gran porcentaje de la población desea recibir la atención debemos enfocarnos en dar solución a un problema a la vez, lo que nos lleva a ser concretos en nuestras intervenciones.</p>
                    </accordion-group>
                    <accordion-group heading="¿Atienden a menores de edad?">
                        <p>Sí, podemos atender a menores de edad, siempre y cuando contemos con el visto bueno de los padres o tutores. Las primeras llamadas se hacen con ellos para recabar información de la situación que lleve al menor a buscar la ayuda. Se les pide a los padres o tutores tengan el tiempo de tener contacto con el especialista cuando este lo solicite y crea necesario. En todo momento se respetará la confidencialidad que el menor deposite en el psicólogo, pero si se identifica que su seguridad o vida están en riesgo, invariablemente se dará prioridad a esto último por lo que, si es necesario, se hablará con los padres de la situación.</p>
                    </accordion-group>
                    <accordion-group heading="¿Qué requieren de mi parte?">
                        <p>Nuestro servicio es gratuito y tenemos una alta demanda de atención constantemente, te pedimos paciencia y constancia para buscar al psicólogo que te ha estado atendiendo.</p>
                        <p>Si estás en atención vía chat, date el tiempo de estar leyendo y contestando en el momento, ya que de otra manera si te distraes, pasa el tiempo y la atención se tendría que cerrar y deberás comunicarte hasta el día siguiente.</p>
                        <p>Así también te pedimos seas respetuoso y claro con el profesional de la salud que te atiende, en caso de tener dudas, quejas, sugerencias y felicitaciones sobre nuestros servicios, ponemos a tu disposición la línea de SIMITEL: 800 911 66 66; estamos muy interesados en ofrecer un servicio de calidad por lo que tu opinión nos ayudará a mejorar.</p>
                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>
</div>
