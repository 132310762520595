<div class="container login-container">
    <div class="row">
        <div class="col-10 col-lg-4 col-md-6  mx-auto">
           
            <div class="login-card">
                <h3 class="text-center m-0">Administrador</h3>
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" ngNativeValidate>
                    <div class="form-row">
                        <div ngNativeValidate class="form-group col-12">
                            <label for="inputUsuario">Usuario</label>
                            <input [(ngModel)]="Usuario" name="Usuario" type="text" class="form-control" id="inputUsuario"
                                required />
                        </div>
                        <div class="form-group col-12">
                            <label for="inputpassword">Contraseña</label>
                            <input [(ngModel)]="Password" name="Password" type="password" class="form-control" id="inputpassword"
                                required />
                        </div>
                    
                        <div class="col-12">
                            <button class="btn btn-primary w-100" type="submit" [disabled]="loading">
                                <span *ngIf="loading; else span" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <ng-template #span>
                                    <span>Entrar</span>
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>