<div class="container container-full">

    <div class="row">
        <div class="col-12 mx-auto text-center pt-5">
            <div class="col-12 text-left mb-3">
                <h2>Test Histórico <button class="btn btn-primary"  style="float:right" routerLink="/administrador/gestor" >
                    <span > ❮ Regresar</span>
                </button></h2>
            </div>
            <div class="col-12">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" ngNativeValidate class="pb-4 row" >
                    <div class="form-group col-12 col-md-6">
                        <label for="fechaInicio">Fecha Inicio:</label>
                        <input type="date" ngModel="fechaInicio" name="fechaInicio" id="fechaInicio"
                            class="form-control" required>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="fechafinal">Fecha Final:</label>
                        <input type="date" ngModel="fechafinal" name="fechafinal" id="fechafinal"
                            class="form-control" required>
                    </div>
                    <div class="form-group col-12">
                        <button type="submit" class="btn btn-primary"> Consultar</button>
                    </div>
                </form>
            </div>
            <div class="col-12">
                <div class="table-responsive"> 
                    <table class="table" *ngIf="this.show">
                        <thead class="thead">
                            <tr>
                                <th>Test</th>
                                <th>Correo</th>
                                <th>Asigno</th>
                                <th>Atendio</th>
                                <!-- <th>Revisar</th> -->
                            </tr>
                        </thead>
                        <tbody >
                        
                            <tr class="hover" *ngFor="let test of tests | paginate: {itemsPerPage: 10, currentPage: pageActual}">
                                <td >{{test.nombreTest}}</td>
                                <td>{{test.correo}}</td>
                                <td >{{test.psicologoCanalizador}}</td>
                                <td >{{test.psicologoReceptor}}</td>
                                
                                <!-- <td > <span class="bg-success"></span></td> -->
                            </tr>
                            <div class="col-12 text-center" style="display: flex;">
                                <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true" style="margin: auto;"> </pagination-controls>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>  
