import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimisaeService } from '../../../services/simisae.service'

@Component({
  selector: 'app-votos',
  templateUrl: './votos.component.html',
  styleUrls: ['./votos.component.css']
})
export class VotosComponent implements OnInit {
 
  tests!: any[];
  pageActual: number = 1;

  constructor(private _simiSAE: SimisaeService,private router: Router) {
    this.getTest();
    if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
      this.router.navigate(['/administrador/login']);
    }
   }

  ngOnInit(): void {
  }
  getTest() {
    this._simiSAE.GetVotos(sessionStorage.getItem('token')).subscribe((resp: any) => {
      console.log(resp)
      this.tests = resp.reverse();
    })
  }

}
