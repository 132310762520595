import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { StreamingService } from '../../services/streaming.service'
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;
  loading: boolean | undefined
  success: boolean | undefined
  showBotton: boolean | undefined
	oneAtATime = true;



  constructor(
    private router: Router,
    private streamingService: StreamingService,
    private _urlcanonicaservice: UrlcanonicaService,
    private _seoService: SeoService,
    public meta: Meta,
    public title: Title
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    });
  }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}

  ngOnInit() {}

  toggleDisplay() {
    this.showBotton = true;

    setTimeout(() => {
      this.showBotton = false;
    }, 3500);
  }

  onSubmit(form: NgForm) {
    this.loading = true

    var estado = Number(form.value.estado);


    const formulario = {
      CNombre: form.value.nombre,
      CEmail: form.value.email,
      CAsunto: 'Contacto',
      CPaginaOrigen: 'SIMISAE',
      CComentarios: form.value.mensaje,
      IdEstado: estado,
      CTelefono: form.value.telefono
    }



    this.streamingService.enviarContacto(formulario).subscribe(
      (resp: any) => {
        console.log(resp)
        this.loading = false
        this.success = true
        form.reset()
      },
      error => {
        console.log(error)
        this.success = false
      }
    )
  }
}
