import { NavigationEnd, Router } from '@angular/router';
import { ApplicationRef, Component, OnInit, ViewChild   } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel'
import { InformateService } from '../../services/informate.service'
import { StreamingService } from '../../services/streaming.service'
import { DomSanitizer } from '@angular/platform-browser'
import { Meta, Title } from '@angular/platform-browser'
import { UrlcanonicaService } from '../../services/urlcanonica.service'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SeoService } from '../../services/seo.service';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Component({
  selector: 'app-inicio2',
  templateUrl: './inicio2.component.html',
  styleUrls: ['./inicio2.component.css'],
  providers: [
		{
			provide: CarouselConfig,
			useValue: { interval: 5000, showIndicators: true }
		}
	]
})
export class Inicio2Component implements OnInit {
supportweb: boolean | undefined;
	isModalShown = false
	videos!: any[];
	notas!: any[];
	isShow = true
	newstr: any
	showBotton: boolean | undefined
	public edited = false
	RouteText : any;
    tituloseo: any;
    urlcanonicaseo: any;
    imagenseo: any;
    keywordsseo: any;
    descripcionseo: any;


	constructor(
		private router: Router,
		private _urlcanonicaservice: UrlcanonicaService,
		private informateService: InformateService,
		private streamingService: StreamingService,
		private sanitizer: DomSanitizer,
		public meta: Meta,
		private _seoService: SeoService,
		public title: Title,
		private update: SwUpdate,
        private appRef: ApplicationRef
		
	) {
		this.updateClient();
		this.checkUpdate();
		this.WebpIsSupported();


		this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
			// Modal
			this.edited = true;
		});
	}


	WebpIsSupported() {
		// Si el navegador no tiene el método createImageBitmap, no puede mostrar el formato webp
		if (!self.createImageBitmap){ this.supportweb = false; }
		else {this.supportweb = true;  }
	}


	 //Replicar para todas las paginas Principales
	getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			
			this._urlcanonicaservice.createCanonicalLink();
		
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}

	ngOnInit() {
		this.informateService.getNotas().subscribe((resp: any) => {
			this.notas = resp.reverse().slice(0, 3);
		})
		this.streamingService.getVideos().subscribe((respdos: any) => {
			this.videos = respdos.reverse().slice(0, 4);
		})
	}

	//Descomentar el codigo que esta dentro de gopoup para crear el redireccionamiento
	gopoup(){
        // window.open('https://simiplaneta.com/1er-concurso-nacional-de-diseno.html', '_blank', 'noopener');
    }

    close(){
        this.edited = false;
    }


	toggleDisplay() {
		this.showBotton = true

		setTimeout(() => {
			this.showBotton = false
		}, 3500)
	}

	getNotaUrl(nota: any) {
		this.newstr = nota.titulo.replace(/\s+/g, '-').toLowerCase()
		const removeAccents = (str: string) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");} 
		this.newstr = removeAccents( this.newstr);
		var first = this.newstr.charAt(0);
		var last = this.newstr.charAt(this.newstr.length - 1)
		var r = /^[A-Z]+$/i;
		if( !r.test(first) ){
			this.newstr = this.newstr.slice(1);
		}
		if( !r.test(last) ){
			this.newstr = this.newstr.slice(0, -1);
		}
		this.newstr = this.newstr.replace(/[^a-zA-Z 0-9.]+/g,'-');
		this.router.navigate(['/articulo/' + nota.id + '/' + this.newstr])
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url)
	}
	

	
    updateClient() {
		if (!this.update.isEnabled) {
		  console.log('Not Enabled');
		  return;
		}
		this.update.available.subscribe((event) => {
		  console.log(`current`, event.current, `available `, event.available);
		  if (confirm('Una nueva version del sitio')) {
			this.update.activateUpdate().then(() => location.reload());
		  }
		});
	
		this.update.activated.subscribe((event) => {
		  console.log(`current`, event.previous, `available `, event.current);
		});
	  }
	
	  checkUpdate() {
		this.appRef.isStable.subscribe((isStable) => {
		  if (isStable) {
			const timeInterval = interval(8 * 60 * 60 * 1000);
	
			timeInterval.subscribe(() => {
			  this.update.checkForUpdate().then(() => console.log('checked'));
			  console.log('update checked');
			});
		  }
		});
	  }
  
}
