import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.css']
})
export class TestsComponent implements OnInit {
  tests = [
    {
      nombre: "Depresión"
    },
    {
      nombre: "Trastorno bipolar de la personalidad"
    },
    {
      nombre: "Duelo: ¿Como enfrento adecuadamente la muerte de un ser querido?"
    },
    {
      nombre: "Trastornos de ansiedad"
    },
    {
      nombre: "Estrés"
    },
    {
      nombre: "Consumo de sustancias (Drogadicción)"
    },
    {
      nombre: "Alcoholismo"
    },
    {
      nombre: "Bulimia"
    },
    {
      nombre: "Anorexia"
    },
    {
      nombre: "¿Ejerzo violencia con mis seres queridos?"
    },
    {
      nombre: "¿Soy una persona que sufre la violencia de pareja?"
    },
    {
      nombre: "Trastorno de personalidad (Histriónico)"
    },
    {
      nombre: "Trastorno de personalidad (Narcisista)"
    },
    {
      nombre: "Trastorno de personalidad (Anti Social)"
    },
    {
      nombre: "Trastorno del deseo sexual"
    },
    {
      nombre: "Trastorno de erección"
    },
    {
      nombre: "Trastornos orgásmicos"
    },
    {
      nombre: "Trastorno de eyaculación precoz"
    },
    {
      nombre: "Trastorno dispareunia"
    },
    {
      nombre: "Trastorno vaginismo"
    },
    {
      nombre: "Codependencia"
    },
    {
      nombre: "Pareja: ¿Establezco una relación sana con mi pareja?"
    },
    {
      nombre: "Familia: ¿Establezco una relación sana con mi familia?"
    },
    {
      nombre: "¿Cómo es la relación con mi hijo adolescente?"
    },
    {
      nombre: "Vejez"
    },
    {
      nombre: "¿Tengo problemas con mi autoestima?"
    },
    {
      nombre: "¿Por qué es importante aceptar nuestro destino?"
    },
    {
      nombre: "¿Soy una persona mentalmente sana?"
    }
  ];
  newstr: any;
  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;

 constructor(
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private _seoService: SeoService,
    public meta: Meta,
    public title: Title
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
      console.log(event.url)
    });
  }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url', content: this.urlcanonicaseo })
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}

 

  getTest(test: any) {
    this.newstr = test.replace(/\s+/g, "-").toLowerCase();
    const removeAccents = (str: string) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");} 
		this.newstr = removeAccents( this.newstr);
		var first = this.newstr.charAt(0);
		var last = this.newstr.charAt(this.newstr.length - 1)
		var r = /^[A-Z]+$/i;
		if( !r.test(first) ){
			this.newstr = this.newstr.slice(1);
		}
    if( !r.test(last) ){
      this.newstr = this.newstr.slice(0, -1);
    }
		this.newstr = this.newstr.replace(/[^a-zA-Z 0-9.]+/g,'-');
    this.router.navigate(["/tests/" + this.newstr]);
  }
  ngOnInit() {
  }
}
