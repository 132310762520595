import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimisaeService } from '../../../services/simisae.service'


@Component({
  selector: 'app-testlistado',
  templateUrl: './testlistado.component.html',
  styleUrls: ['./testlistado.component.css']
})
export class TestlistadoComponent implements OnInit {

  tests!: any[];
  pageActual: number = 1;

  constructor(private _simiSAE: SimisaeService,private router: Router) {
    if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
      this.router.navigate(['/administrador/login']);
    }
    this.getTest();
   }

  ngOnInit(): void {
  }


  getTest() {
    this._simiSAE.GetSinEvaluar(sessionStorage.getItem('token')).subscribe((resp: any) => {
      console.log(resp)
      this.tests = resp;
    })
  }

  getDetalle(test:any){
    this.router.navigate(["/administrador/testdetalle/" + test.id ]);
  }

}
