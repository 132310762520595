import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isOpen = false
  isCollapsed = false
  navbarOpen: boolean | undefined;
  loggedInAdmin = false;

  constructor(
    private router: Router,
    private pixel: PixelService
    )
    {
    this.router.events.subscribe(value => {
      var starloAdmin = sessionStorage.getItem('loggedInAdmin')
      if(starloAdmin == '1'){
        this.loggedInAdmin=true
      }else  if(starloAdmin != '1'){
        this.loggedInAdmin=false
      }    
    });
  }

  ngOnInit() {
    
  }
  onNavbarMovil() {
    this.navbarOpen = false;
  }

  logout() {
    sessionStorage.clear();
     this.loggedInAdmin=false
     this.router.navigate(['/administrador/login']);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  onConsent(): void {
    this.pixel.initialize();
  }

}
