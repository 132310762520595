<footer>
    <section class="footer-cont">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-md-6 text-white">
                            <a href="http://www.porunpaismejor.com.mx/" target="_blank"> <img
                                    style="width: 150px !important;" src="assets/images/logo-por-un-pais-mejor.svg"></a>
                            <p class="pt-3 pb-3">Independencia No. 26, (Eje 6 Sur)<br>
                                Col. Independencia, C.P. 03630,<br>
                                Alcaldía de Benito Juárez, Ciudad de México</p>
                        </div>
                        <div class="col-md-6 pb-4">
                            <strong>EMAIL</strong><br>
                            <a
                                href="mailto:centrodiagnostico@simisae.com.mx">centrodiagnostico@simisae.com.mx</a><br>
                            <strong>TELÉFONO</strong><br>
                            <a href="tel:8009113232"> 800 911 32 32</a><br>
                            <a href="https://www.facebook.com/SIMISAE" target="_blank"><img
                                    src="assets/images/ico-facebook.svg" alt=""> </a>
                            <a href="https://twitter.com/simisaemx" target="_blank"><img
                                    src="assets/images/ico-twitter.svg" alt="">
                            </a>
                            <a href="https://www.youtube.com/channel/UCmvExLVJA7dCSeurwVJN1CA" target="_blank"><img
                                    src="assets/images/ico-youtube.svg" alt="">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 d-none">
                    <strong>RECIBE NUESTRAS NOTIFICACIONES</strong><br><br>
                    <form class="suscribete-form" #f="ngForm" (ngSubmit)="enviarSuscripcion(f)" ngNativeValidate>
                        <div class="form-group">
                            <input ngModel name="correo" type="email" class="form-control" id="inputEmail4"
                                placeholder="Email" required>
                        </div>
                        <button type="submit" class="btn btn-uno">SUSCRIBIRME</button>
                    </form>
                    <p *ngIf="Oksub"><strong style="color:#fff; font-size:16px">GRACIAS POR TU SUSCRIPCIÓN</strong></p>
                    <p *ngIf="Okerr"><strong style="color:#fff; font-size:16px">YA TE ENCUENTRAS REGISTRADO</strong></p>
                    <p>Infórmate sobre nuestros nuevos temas en
                        SIMICHAT, ¡participa!</p>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-12 footer-legales text-center">
                <p class="text-white pb-2 pt-2">© Copyright <span [innerHTML]="year"></span>. Centro SIMI de Salud Emocional
                    |<a [routerLink]="['/aviso-legal']" routerLinkActive="router-link-active"> Aviso legal </a>|
                    <a [routerLink]="['/aviso-de-privacidad']" routerLinkActive="router-link-active">Aviso de
                        privacidad</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<a href="https://bit.ly/Necesitoapoyopsicologicogratuito" target="_blank" class="whatsapp"><img src="../../../assets/images/iconowhatsapp.png"></a>
<a routerLink="psicologos-en-linea" class="email"><img src="../../../assets/images/iconoemail.png"></a>
