import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  show = false;


  constructor(
    private router: Router
  ) { 
    if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
      this.router.navigate(['/administrador/login']);
    }
    if((sessionStorage.getItem('username') == 'Gabriela Veronica Jimenez Vera' || sessionStorage.getItem('username') == 'Juan Carlos')){
      this.show = true;
    }

  }

  ngOnInit(): void {
  }

}
