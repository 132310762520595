
<div class="container container-full">

    <div class="row">
        <div class="col-12 mx-auto  pt-5">
            <div class="col-12 text-left mb-3">
                <h2>Test Histórico <button class="btn btn-primary"  style="float:right" routerLink="/administrador/gestor" >
                    <span > ❮ Regresar</span>
                </button></h2>
            </div>
            <div class="col-12">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" ngNativeValidate class="pb-4 row" >
                    <div class="form-group col-12 col-md-6">
                        <label for="selectEvent">Evento:</label>
                      
                        <select ngModel class="custom-select" name="selectEvent" required>


                                        
                            <option selected value="">Selecciona...</option>
                            <option *ngFor="let webinar of webinars" value="{{ webinar.tema }}"> {{ webinar.fecha}} | {{ webinar.tema }}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-md-6 pt-4">
                        <button type="submit" class="btn btn-primary"> Consultar</button>
                    </div>
                </form>
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="this.show"> 
                    <button class="btn btn-success mb-4" (click)="exportToExcel()">Exportar a Excel</button>
                    <table class="table" id="season-tble" >
                        
                        <thead class="thead">
                            <tr>
                                <th>Email</th>
                                <th>Nombre</th>
                                <th>Edad</th>
                                <th>Ubicación</th>
                                <th>Status</th>
                                <th>Fecha Registro</th>
                                <!-- <th>Revisar</th> -->
                            </tr>
                        </thead>
                        <tbody >
                        
                            <tr class="hover" *ngFor="let test of tests ">
                                <td >{{test.email}}</td>
                                <td >{{test.nombre}}</td>
                                <td >{{test.edad}}</td>
                                <td >{{test.lugardeResidencia}}</td>
                                <td >{{ test.status}} </td>
                                <td >{{ test.fecreg}} </td>
                                <!-- <td > <span class="bg-success"></span></td> -->
                            </tr>
                            
                        </tbody>
                    </table>
                    <div class="col-12 text-center" style="display: flex;">
                                <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true" style="margin: auto;"> </pagination-controls>
                            </div>
                </div>
            </div>
        </div>
    </div>
</div>  
