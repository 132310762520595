import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {
  test: string | undefined;
  // defaultMessage =
    
  constructor() {}

  selectEvaluacion(test: string, questions: any): string {
    switch (test) {
      case 'Anorgasmia':
        return this.anorgasmiaEval(questions);

      case 'Depresión':
        return this.depresionEval(questions);

      case 'Trastorno Bipolar de la personalidad':
        return this.bipolaridadEval(questions);

      case 'Duelo: ¿Cómo enfrento adecuadamente la muerte de un ser querido?':
        return this.muerteDeUnSerQueridoEval(questions);

      case 'Trastornos de ansiedad':
        return this.ansiedadEval(questions);

      case 'Estrés':
        return this.estresEval(questions);

      case 'Consumo de sustancias (Drogadicción)':
        return this.drogadiccionEval(questions);

      case 'Alcoholismo':
        return this.alcoholismoEval(questions);

      case 'Bulimia':
        return this.bulimiaEval(questions);

      case 'Anorexia':
        return this.anorexiaEval(questions);

      case '¿Ejerzo violencia con mis seres queridos?':
        return this.violenciaSeresQueridosEval(questions);

      case '¿Soy una persona que sufre la violencia de pareja?':
        return this.violenciaDePareja(questions);

      case 'Trastorno de personalidad (Histriónico)':
        return this.histrionicoEval(questions);

      case 'Trastorno de personalidad (Narcisista)':
        return this.narcicistaEval(questions);

      case 'Trastorno de personalidad (Antisocial)':
        return this.antiSocialEval(questions);

      case 'Trastorno del deseo sexual':
        return this.trastornoSexualEval(questions);

      case 'Trastorno de erección':
        return this.ereccionEval(questions);

      case 'Trastornos orgásmicos':
        return this.trastornosOrgasmicosEval(questions);

      case 'Trastorno de eyaculación precoz':
        return this.eyaculacionPrecozEval(questions);

      case 'Trastorno dispareunia':
        return this.dispareuniaEval(questions);

      case 'Trastorno vaginismo':
        return this.vaginismoEval(questions);

      case 'Codependencia':
        return this.codependenciaEval(questions);

      case 'Pareja: ¿Establezco una relación sana con mi pareja?':
        return this.parejaEval(questions);

      case 'Familia: ¿Establezco una relación sana con mi familia?':
        return this.relacionConFamiliaEval(questions);

      case '¿Cómo es la relación con mi hijo adolescente?':
        return this.hijoAdolescenteEval(questions);

      case 'Vejez':
        return this.vejezEval(questions);

      case '¿Tengo problemas con mi autoestima?':
        return this.autoestimaEval(questions);

      case '¿Por qué es importante aceptar nuestro destino?':
        return this.destinoEval(questions);

      case '¿Soy una persona mentalmente sana?':
        return this.mentalmenteSanoEval(questions);

      default:
        return 'nada';
    }
    
  }

  /** Regresa un total de preguntas que fueron contestadas con 'si'. */
  getPositives(questions: any): number {
    return questions.reduce((accumulator: number, question: any) => {
      if (question.response === 'si') {
        accumulator += 1;
      }
      return accumulator;
    }, 0);
  }

  getNegatives(questions: any): number {
    return questions.reduce((accumulator: number, question: any) => {
      if (question.response === 'no') {
        accumulator += 1;
      }
      return accumulator;
    }, 0);
  }

  /** Regresa un arreglo con las preguntas que fueron contestadas con 'si' y les agrega un index para saber de que pregunta se trata. */
  getArraysOfPositives(questions: any): any[] {
    const array: any[] = [];
    questions.forEach((question: { response: string; index: any; }, index: number) => {
      if (question.response === 'si') {
        array.push((question.index = index + 1));
      }
    });
    return array;
  }

  /** Regresa arreglo con respues no */
  getArraysOfNegatives(questions: any): any[] {
    const array: any[] = [];
    questions.forEach((question: { response: string; index: any; }, index: number) => {
      if (question.response === 'no') {
        array.push((question.index = index + 1));
      }
    });
    return array;
  }

  estresEval(questions: any) {
    let count = 0;
    questions.forEach((question: { response: string; }) => {
      count += parseInt(
        question.response.substring(0, question.response.indexOf('-'))
      );
    });

    if (count > 4 && count < 11) {
      return 'De acuerdo con los puntajes obtenidos, existen indicadores de estrés en tu vida, los cuales no te causan, al parecer, un conflicto grave; el buscar formas de canalizarlo, te puede ayudar a que este no se incremente y pueda convertirse en un trastorno. Te sugerimos que realices un reacomodo en tu vida y organices cada una de las situaciones que te provocan estrés y le des seguimiento a cada un a de ellas por orden de importancia. Para reducir el estrés es importante no querer resolverlas todas al mismo tiempo. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (count > 10 && count < 22) {
      return 'De acuerdo con los datos, podemos mencionarte que el estrés puede formar parte de tu vida diaria, lo cual te puede provocar un Trastorno de Ansiedad y dañar considerablemente tu salud física y emocional. Te sugerimos que realices un reacomodo en tu vida y organices cada una de las situaciones que te provocan estrés y le des seguimiento por orden de importancia. Para reducir el estrés, es importante no querer resolverlas todas al mismo tiempo. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (count >= 22) {
      return 'De acuerdo con los puntajes obtenidos, el estrés forma parte en tu vida diaria, lo cual es probable que afecte varias áreas de tu vida como: falta de memoria, agotamiento, irritabilidad y problemas físicos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema, te informarán sobre diversas formas funcionales para manejar el estrés, y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  mentalmenteSanoEval(questions: any) {
    const positivesCount = this.getPositives(questions);
    const negativesCount = this.getNegatives(questions);

    if(negativesCount > 7 ){
      return 'Es recomendable que busques apoyo profesional, ya que al no expresar tus emociones y no adaptarte a las crisis cotidianas de la vida, te puede provocar frustración y obstaculizar tu desarrollo. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    
    if (positivesCount > 15) {
      return 'De acuerdo a los datos recibidos no presentas problemas con respecto a tu salud mental, posiblemente hayas atravesado alguna situación conflictiva que te generara enojo, frustración o desánimo. Sin embargo, tienes la madurez necesaria para adaptarte a los cambios y continuar con esperanza hacia el futuro. Si tienes alguna inquietud al respecto te sugerimos comunicarte con nosotros.';
    } 
    
    if (positivesCount > 7 || positivesCount < 16) {
      return 'De acuerdo a los datos recibidos te recomendamos fortalecer algunas áreas importantes en tu persona, como la asertividad, la autoestima y el auto cuidado; dicho proceso se puede llevar a cabo a través del tratamiento psicológico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
    
  }

  destinoEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount > 2) {
      return 'Es probable que deposites en la suerte, y en los demás, la responsabilidad de lo que te sucede y de las decisiones que tomas. El pasado no se puede modificar, el futuro no se puede predecir; es en el presente en donde puedes actuar tomando en consideración la experiencia que has adquirido en el pasado y tus expectativas hacia un futuro. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (positivesCount < 3) {
      return 'La frustración y el enojo ocasionados por las situaciones que estés viviendo, te pueden llevar a pensar que el destino o la suerte se confabulan en tu contra; pero pasado el enojo vuelves a tomar consciencia de la responsabilidad de tus decisiones, generando con ello la capacidad de hacerte responsable de tu vida.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  autoestimaEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount > 2) {
      return 'Es probable que presentes problemas con tu autoestima, lo cual puede obstaculizar tu sano desarrollo y afectar todas las áreas de tu vida. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return 'Con base a la información recibida no se cuentan con elementos suficientes para realizar una valoración completa, por lo que te invitamos a comunicarte con nosotros para que nos hagas saber cuáles son las posibles áreas de tu personalidad que se encuentran en conflicto. De cualquier manera te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
  }

  vejezEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount >= 3) {
      return 'Es probable que la manera en que estás enfrentado la vejez, y la actitud que estás tomando ante las diversas circunstancias que se te presentan en la vida diaria, te impidan desarrollarte adecuadamente y ser feliz. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
    }

    return 'Es probable que en estos momentos de tu vida, en los que puedes desarrollar diversos cambios físicos y emocionales debido al proceso de natural de envejecimiento que enfrentas, se están generando molestias o conflictos en tu persona, los cuales pueden afectar el área de pareja, familia, laboral o social, por lo que te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
  }

  hijoAdolescenteEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount >= 3) {
      return 'Es muy probable que la comunicación que mantienes con tu hijo, y el trato que le das, contribuyan a que surjan conflictos de relación que impiden el adecuado desarrollo familiar. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre el tema y te podrán ayudar a identificar cuáles son aquellas actitudes que inconscientemente afectan la autoestima de tu hijo, lo cual puede provocar que su rebeldía o aislamiento incremente y deteriore la relación familiar. Recuerda que, a mayor control, mayor rebeldía.';
    }

    return 'Aunque es probable que existan problemas en cuanto a la comunicación y el respecto mutuo entre tu hijo y tú, no existen elementos suficientes para identificar en que área específica se está afectando la relación; por lo que te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría de este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir terapia familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
  }

  relacionConFamiliaEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const negativesArray = this.getArraysOfNegatives(questions);
    const setOne = [1, 6, 13];
    const setTwo = [2, 8, 14];
    const setThree = [3, 8, 15];
    const setFour = [4, 10, 16];
    const setFive = [5, 11, 17];
    const setSix = [7, 12, 18];

    const intersectionOne = _.intersection(setOne, negativesArray);
    const intersectionTwo = _.intersection(setTwo, negativesArray);
    const intersectionThree = _.intersection(setThree, negativesArray);
    const intersectionFour = _.intersection(setFour, negativesArray);
    const intersectionFive = _.intersection(setFive, negativesArray);
    const intersectionSix = _.intersection(setSix, negativesArray);

      if((intersectionOne.length > 0 && positivesArray.length < 15) || (intersectionTwo.length > 0 && positivesArray.length < 15) || (intersectionThree.length > 0 && positivesArray.length < 15 ) || (intersectionFour.length > 0 && positivesArray.length < 15 ) || (intersectionFive.length >0 && positivesArray.length < 15 ) || (intersectionSix.length >0 && positivesArray.length < 15)){
        return 'Con base en la evaluación no encontramos elementos que indiquen la existencia de problemas en tu relación familiar; si existe alguna situación que te incomode o te inquiete, te invitamos a que reflexiones sobre las probables causas, para evitar que estas actitudes tuyas o de algún miembro de la familia, puedan provocar la desunión familiar. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, por ejemplo, la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
      }else{
        if (intersectionOne.length > 0 && positivesArray.length > 14){
          return 'Posiblemente en tu familia se presentan problemas de cooperación o apoyo mutuo, lo cual puede generar diversas reacciones entre sus integrantes, desde la soledad y sensación de no tener apoyo, hasta la frustración y el enojo por enfrentar los problemas de manera individual; esto puede provocar graves conflictos, desarmonía y desunión familiar. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico),  en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación; permitiendo generar una actitud más cooperadora y, si fuera necesario, referirlos a alguna institución donde puedan recibir terapia psicológica, como la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else if (intersectionTwo.length > 0 && positivesArray.length > 14){
          return 'Es probable que en tu familia se presenten dificultades para comunicarse ya sea en todos los aspectos, o en algún tema en particular. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema, sobre cómo desarrollar habilidades para mejorar la comunicación, y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, como por ejemplo la terapia de pareja o familia. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else if (intersectionThree.length > 0 && positivesArray.length > 14){
          return 'Puede ser que en la familia no se permita una expresión abierta de los afectos, o bien, que solo puedan expresar los que la familia considera correctos o aceptables, lo que podría generar una tensión constante y la sensación de no sentirse comprendidos; te recomendamos que reflexiones para que puedas identificar qué es lo que está impidiendo que exista una expresión libre de los sentimientos entre los miembros de tu familia. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, por ejemplo, la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else if (intersectionFour.length > 0 && positivesArray.length > 14){
          return 'Probablemente tú y tu familia no están enfrentando los conflictos de la manera más adecuada, ya que puede ser que los estén evadiendo, o bien, que estén utilizando la agresión y/o la sumisión para tratar de resolverlos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, por ejemplo, la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else if (intersectionFive.length > 0 && positivesArray.length > 14){
          return 'Es posible que en tu familia no se respete la individualidad de sus integrantes, o que exista tal nivel de alejamiento por parte de alguno(s) de sus miembros que puede provocar la desintegración familiar. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, por ejemplo, la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else if (intersectionSix.length > 0 && positivesArray.length > 14){
          return 'Tal vez en tu familia las normas o reglas son muy estrictas, o bien, por el contrario, puede existir tal permisividad y flexibilidad en dichas reglas, que no está claro quién y ni cómo se ejerce la autoridad, lo que puede generar una gran confusión entre los miembros de tu familia, de lo que está permitido y no está permitido realizar. Esto puede representar un alto riesgo para la organización y continuidad de la familia. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico, por ejemplo, la terapia de pareja o familiar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
        }else{
          return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
        }
      }
      // return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";

  }

  codependenciaEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount >= 3) {
      return 'De acuerdo con los resultados obtenidos, es probable que presentes problemas en la forma de relacionarte, en donde puede existir la violencia, soledad, y la angustia por el temor de perder a la pareja, y que continúes al lado de esta pareja a pesar del sufrimiento que te genera el vivir con ella. Es importante tomar acción a la brevedad para evitar que este sufrimiento provocado por la codependencia te aniquile emocionalmente, y que se ponga en riesgo tu salud física y emocional, así como la de tu familia. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount < 3) {
      return 'De acuerdo a las respuestas obtenidas, no reúnes los criterios necesarios para determinar que presentas una Relación de Codependencia, sin embargo, es importante que revises con el especialista las situaciones que presentas relacionadas con la autoestima y asertividad (aprender a marcar límites y defenderte, así como a decir y a pedir lo que necesitas), para poder ser independiente, y lograr con ello, el desarrollo del auto-respeto. De esta manera los demás aprenderán a respetarte: la autoestima es la clave del éxito personal, constituye la herramienta y el "escudo" que nos permite evitar que los demás abusen de nosotros. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  parejaEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const negativesArray = this.getArraysOfNegatives(questions);
    const setOne = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const setTwo = [17, 18];
    const setThree = [7, 8, 9];
    const setFour = [10, 11, 12, 13, 14, 15, 16];
    const setFiveNeg = [1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    const setAllres = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);
    const intersectionThree = _.intersection(setThree, positivesArray);
    const intersectionFour = _.intersection(setFour, positivesArray);
    const interAll = _.intersection(setAllres, positivesArray);
    const intersectionNega = _.intersection(setFiveNeg, negativesArray);
    

    if (intersectionOne.length > 0 && (intersectionTwo.length > 0 && intersectionFour.length == 0)) {
      return 'Es probable que existan dificultades de comunicación en tu relación de pareja, no están enfrentando los conflictos de la manera más adecuada, ya que pareciera que los están evadiendo y utilizando la agresión y/o la sumisión para tratar de resolverlos, generando un círculo de violencia. Uno de nuestros psicólogos te ayudará sobre las habilidades que puedes desarrollar para mejorar tu relación de pareja, así como para orientarte sobre otras formas funcionales de enfrentar los conflictos. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (intersectionThree.length === 3 && intersectionNega.length === 15) {
      return 'Probablemente presentes problemas en el área de sexualidad, ya sea porque ambos tienen prejuicios o información errónea sobre el tema, y/o porque manejan valores distintos, por lo que te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda, te invitamos a que te comuniques con nosotros para que nuestros especialistas te ayuden a identificar los obstáculos que presentan en esta área y podamos referirlos con alguna institución donde puedan recibir tratamiento especializado. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    
    if (intersectionFour.length > 1  && (intersectionTwo.length > 0 && positivesArray.some((question: number) => question === 2))) {
      return 'Es probable que se les dificulte tanto a tu pareja como a ti el marcar límites con sus familias de origen y/o con sus amigos, lo cual puede deteriorar su relación, así como el desarrollo de conflictos debido a la forma en que toman las decisiones, ya sea porque alguno de los dos suele imponerse, lo cual resulta injusto para el otro, o bien, porque no logran realizar acuerdos. Esto les lleva a no reconocer y respetar la individualidad de cada uno, así como el admitir que el hecho de ser pareja no implica cambiar o ser distintos para agradar al otro. Uno de nuestros especialistas te podrá orientar sobre diversas opciones funcionales para mejorar tu situación y, de ser necesario, canalizarte con alguna institución donde puedan recibir apoyo psicológico y resolver sus diferencias. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    
    if(interAll.length < 8){
      return 'Con base en la evaluación, no encontramos elementos que indiquen que existen problemas graves en tu relación de pareja, sin embargo, si existe alguna situación que te incomode o te inquiete, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
    }

    if(interAll.length > 10){
      return 'Con base en la evaluación, tienes una relación de pareja dónde no hay comunicación ni respeto a la individualidad, permiten la intromisión de la familia en sus decisiones como pareja, lo cual puede ocasionar un resentimiento y enojo por no verse respetados. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
    }

    return 'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';;
  }

  vaginismoEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const setOne = [3, 5, 6, 7];
    const setTwo = [4, 8, 9, 10];
    const setComodin = [1, 2];

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);
    const intersectionComodin = _.intersection(setComodin, positivesArray);

    if (positivesArray.length === 1) {
      return 'La información recavada con el cuestionario no nos brinda elementos suficientes para evaluar que presentas un Trastorno de Vaginismo. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (positivesArray.length === 9) {
      return 'Existe la posibilidad de que el Trastorno de Vaginismo que presentas se deba tanto a un factor físico, por lo cual es importante identificar la causa, así como las posibles causas psicológicas tales como la percepción errónea de la sexualidad: divisándola como "indebida","pecaminosa" o "sucia". Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (intersectionOne.length > 0 && (intersectionComodin.length > 0 && intersectionTwo.length === 0 )) {
      return 'Existe la posibilidad que presentes un Trastorno de Vaginismo debido a un factor psicológico; te recomendamos realizar una revisión de las ideas o conceptos que tengas con respecto a tu sexualidad, así como los miedos que se generan a través de los mitos o tabúes. La visita al Sexólogo Clínico es de las opciones más recomendadas para poder abordar esta problemática. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (intersectionTwo.length > 1 && (intersectionComodin.length > 0 && intersectionOne.length === 0 )) {
      return 'Existe la posibilidad de que padezcas este trastorno debido a factores físicos, como el presentar algún proceso de cicatrización o cirugía, alguna infección de transmisión sexual, o bien, de presentar alguna deficiencia de estrógenos que provoque una sequedad vaginal. Dichos factores pueden provocar que rechaces las relaciones cóitales por miedo al dolor. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
  
    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  dispareuniaEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const negativesArray = this.getArraysOfNegatives(questions);
    const setOne = [1, 4, 7, 8, 12];
    const setTwo = [3, 5, 6, 9, 10, 11];
    const setComodin = [2];
    const setNegat = [2, 3, 5, 6, 9, 10, 11];

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);
    const intersectionComidin = _.intersection(setComodin, positivesArray);
    //const intersectionNega = _.intersection(setNegat, negativesArray);

    

    if (positivesArray.length == 1) {
      return 'La información proporcionada en el cuestionario no nos brinda elementos suficientes para identificar las posibles causas de un Trastorno de Dispareunia, o bien, para identificar si en realidad lo padeces, por lo que te invitamos a que te comuniques con nosotros para que nos informes sobre la manera en que has desarrollado tu salud física y emocional a lo largo de tu vida. De cualquier manera, con los datos que hemos obtenido por tu parte, realizaremos una valoración del caso, la cual te será enviada a tu correo electrónico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }else if (positivesArray.length == 11) {
      return 'Existe la posibilidad de que este trastorno que padeces se deba tanto a un factor físico, para el cual es importante identificar la causa, así como a factores psicológicos como la percepción de la sexualidad como algo "indebido" o "pecaminoso". Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
    }else if(positivesArray.length > 1 && positivesArray.length < 12){
      
      if(intersectionOne.length > 0 && (intersectionTwo.length === 0 && intersectionComidin.length > 0)){
        return 'Existe la posibilidad de que padezcas un Trastorno de Dispareunia debido a factores psicológicos como, por ejemplo, el haber recibido una educación represiva en este tema.  Es probable también, que si presentas conflictos con tu pareja, estos también puedan influir en el desarrollo de este trastorno. Es importante consultar a un Sexólogo Clínico, con el objetivo de que puedas conocer más sobre este padecimiento. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
      }

      if(intersectionTwo.length > 0 && (intersectionOne.length === 0 && intersectionComidin.length > 0)){
        return 'Existe la posibilidad de que el trastorno que padeces se presente a causa de factores físicos como presentar alguna reacción alérgica al método anticonceptivo “de barrera” usado, o bien, presentar dolor a causa de un factor posquirúrgico que este ocasionándolo. Te recomendamos que visites al especialista, ya sea ginecólogo o urólogo, para que pueda realizar un estudio completo de tu caso y pueda identificar las causas específicas y te informe sobre el tratamiento recomendado. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), y si tienes alguna otra duda te invitamos a que te comuniques con nosotros.';
      }
     
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  eyaculacionPrecozEval(questions: any) {
    const setOne = [10, 11];
    const setTwo = [2, 3, 4, 5, 6, 7, 8, 9];
    const setComodin = [1];

    const positivesArray = this.getArraysOfPositives(questions);

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);
    const intersectionComidin = _.intersection(setComodin, positivesArray);

    if (positivesArray.length === 1) {
      return 'Con la información proporcionada en el cuestionario, no existen elementos suficientes para evaluar si efectivamente presentas este trastorno, o bien, para identificar cuáles podrían ser las causas, por lo que te invitamos a que te comuniques con nosotros para que nos informes sobre la manera en que has desarrollado tu salud física y emocional a lo largo de tu vida. De cualquier manera, con los datos que hemos obtenido por tu parte, realizaremos una valoración del caso, la cual te será enviada a tu correo electrónico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (positivesArray.length === 10) {
      return 'La posible causa de la Eyaculación Precoz que presentas, puede ser ocasionada por factores físicos y emocionales como problemas de próstata y la incapacidad para poder reconocer la sensación de la eyaculación y de controlar esta. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if ((intersectionTwo.length >= 2 && intersectionComidin.length === 1) && intersectionOne.length === 0) {
      return 'Existe la posibilidad de que este trastorno se deba a una situación de aprendizaje, es decir, es probable que no te sea posible reconocer la sensación de eyaculación, y no has aprendido a detenerla. Este caso se puede tratar a través de un Sexólogo Clínico, quien podrá brindarte la asesoría y tratamiento adecuado, que suelen tener buenos resultados en un plazo relativamente corto. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a comunicarte con nosotros.';
    }else if ((intersectionTwo.length >= 3 && intersectionComidin.length === 0) && intersectionOne.length === 0) {
      return 'Existe la posibilidad de que este trastorno se deba a una situación de aprendizaje, es decir, es probable que no te sea posible reconocer la sensación de eyaculación, y no has aprendido a detenerla. Este caso se puede tratar a través de un Sexólogo Clínico, quien podrá brindarte la asesoría y tratamiento adecuado, que suelen tener buenos resultados en un plazo relativamente corto. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a comunicarte con nosotros.';
    }

    if ((intersectionOne.length < 3 && intersectionComidin.length === 1) && intersectionTwo.length === 0) {
      return 'Existe la posibilidad de que presentes un Trastorno de Eyaculación Precoz debido a un problema orgánico; esto debe ser valorado por un Médico Urólogo quién deberá informarte sobre los diversos tratamientos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }else if ((intersectionOne.length < 3 && intersectionComidin.length === 0) && intersectionTwo.length === 0) {
      return 'Existe la posibilidad de que presentes un Trastorno de Eyaculación Precoz debido a un problema orgánico; esto debe ser valorado por un Médico Urólogo quién deberá informarte sobre los diversos tratamientos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  trastornosOrgasmicosEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const setOne = [1, 7, 8, 9, 10];
    const setTwo = [2, 3, 4, 5, 6];
    //const setThree = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);


    if (positivesArray.length === 9) {
      return 'El Trastorno Orgásmico que probablemente presentes, se debe a una combinación de un factor físico con un problema emocional. Es importante abordar ambos aspectos con el Médico Urólogo y el Sexólogo Clínico para encontrar una solución al conflicto. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    
    if (positivesArray.length === 1){
      return 'Con la información proporcionada en el cuestionario no tenemos elementos suficientes para poder identificar las causas, o bien, para conocer qué tan frecuente presentas esta situación. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    
    if (intersectionOne.length > 1 && intersectionTwo.length == 0) {
      return 'Existe la posibilidad de que padezcas un Trastorno Orgásmico, debido a una causa física; es necesario que te valore un médico especialista: un ginecólogo o urólogo, para determinar la causa y el tratamiento. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
      
    }
    
    if(intersectionOne.length == 0 && intersectionTwo.length > 1){
      return 'Existe la posibilidad de que este trastorno que presentas surja a raíz de una situación psicológica y emocional. Te sugerimos que realices una exploración estudio de cómo se encuentra tu actual relación de pareja con el objetivo de que puedas identificar los diversos factores estresantes que pueden ocasionar este trastorno. Para conocer más al respecto, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }
      
    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  ereccionEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const setOne = [1, 2, 9, 10, 11];
    const setTwo = [3, 4, 5, 6, 7, 8];

    if (positivesArray.length === 1) {
      return 'Con la información proporcionada en el cuestionario, no contamos con elementos suficientes para poder identificar las causas, o bien, para identificar si realmente presentas este trastorno. Te pedimos que te comuniques con nosotros para conocer cómo has desarrollado tu salud física y emocional a lo largo de tu vida, y poder realizar una valoración más completa de tu problemática. De cualquier manera, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }
    if (positivesArray.length === 10 ) {
      return 'La Disfunción Eréctil se puede presentar debido a una combinación de un factor físico con una problemática emocional. Es importante abordar ambos aspectos para solucionar el conflicto. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
      //return 'Existe la posibilidad de que el trastorno que presentas, se deba a factores  psicológicos. Te invitamos a que realices una exploración de cómo está tu actual relación de pareja (revisa si presentas conflictos fuertes), así como de la manera en que organizas tu vida, ya que, si encuentras factores estresantes en algún área de tu vida, es muy probable que esto pueda provocar que presentes un Trastorno de Erección. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);

    if (intersectionOne.length > 2) {
      return 'Existe la posibilidad de que presentes un Trastorno de Disfunción Eréctil debido a una causa física. Es necesario que te realice una valoración el Médico Urólogo para poder determinar el origen y el posible tratamiento, el cual puede ser a través de ingerir medicamentos o de implementar métodos quirúrgicos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if(intersectionTwo.length > 2){
      return 'Existe la posibilidad de que el trastorno que presentas, se deba a factores  psicológicos. Te invitamos a que realices una exploración de cómo está tu actual relación de pareja (revisa si presentas conflictos fuertes), así como de la manera en que organizas tu vida, ya que, si encuentras factores estresantes en algún área de tu vida, es muy probable que esto pueda provocar que presentes un Trastorno de Erección. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.'
    }

    if (intersectionTwo.length >= 5 && intersectionOne.length < 1) {
      return 'La Disfunción Eréctil se puede presentar debido a una combinación de un factor físico con una problemática emocional. Es importante abordar ambos aspectos para solucionar el conflicto. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  trastornoSexualEval(questions: any): string {
    const setOne = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const setTwo = [10, 11, 12];
    const positivesArray = this.getArraysOfPositives(questions);

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);

    if (positivesArray.length === 1) {
      return 'Con la información proporcionada en el cuestionario, no contamos con los elementos suficientes para poder identificar las causas, o bien, para identificar si realmente  presentas este trastorno. Te pedimos que te comuniques con nosotros para conocer cómo has desarrollado tu salud física y emocional a lo largo de tu vida, y poder realizar una valoración más completa de tu problemática. De cualquier manera te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (intersectionOne.length > 1 && intersectionTwo.length === 0) {
      return 'Existe la posibilidad de que este trastorno que presentas sea ocasionado por la percepción que manifiestas sobre la sexualidad, o bien, por poseer conceptos erróneos sobre el tema. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    if (intersectionTwo.length > 0 && intersectionOne.length > 1) {
      return 'Existe la posibilidad de que este trastorno que presentas se deba tanto a factores físicos como psicológicos, como por ejemplo, la forma en la que percibes la sexualidad, por lo cual es importante que acudas primeramente a un chequeo médico, para descartar el factor físico y posteriormente a algún Sexólogo Clínico para evaluar los factores psicológicos y emocionales. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (intersectionTwo.length > 0 && intersectionOne.length === 0) {
      return 'Existe la posibilidad de que el Trastorno del Deseo Sexual que  presentas, se deba a factores físicos, por lo cual es importante que acudas a un chequeo médico con el ginecólogo o urólogo según sea el caso. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  antiSocialEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
     if (positivesArray.length > 5) {
      return 'De acuerdo con los resultados que obtuviste, es probable que presentes este trastorno, y por lo tanto, que varias áreas de tu vida se puedan ver afectadas, es muy posible que presentes conflictos en tus relaciones interpersonales. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }else if (positivesArray.length < 6) {
      return 'De acuerdo con los datos obtenidos, no se cuenta con los elementos necesarios para determinar si padeces este trastorno. Puede que se esté presentando en tu vida una serie de conflictos que te lleven a estar enojado y agresivo, pero no llegar a utilizar o dañar a las personas ya que el remordimiento te lo impediría. Si tienes alguna duda te invitamos a que te comuniques con alguno de nuestros psicólogos en línea.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  violenciaDePareja(questions: any): string {
    const positivesCount = this.getPositives(questions);

    if (positivesCount === 0) {
      return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
    }

    if (positivesCount <= 1) {
      return 'En base a la información recibida no se cuenta con elementos suficientes para realizar una valoración completa,  sin embargo, si existen algunas situaciones que te produzcan conflicto, y angustia, en tu relación de pareja o familia, te sugerimos que busques apoyo profesional. De cualquier manera te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount >= 2) {
      return 'Es probable que padezcas una situación de violencia la cual podría ser peligrosa tanto para ti como para tu familia, por lo que es necesario que busques apoyo profesional. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  depresionEval(questions: any) {
    const positives = this.getPositives(questions);

    if (positives <= 5) {
      return 'Es probable que no presentes un Trastorno del Estado de Animo como tal, sin embargo, es importante mencionar que los síntomas de tristeza o apatía que probablemente presentas, pueden incrementar si no atiendes tus emociones y volverse una depresión; por tal motivo es importante que trates de identificar las causas que los provocan y busques ayuda profesional: de ti depende el salir adelante. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (positives > 5) {
      return 'Es recomendable que busques apoyo profesional, pues podrías presentar un Trastorno del Estado de Ánimo, lo cual puede afectar seriamente todas las áreas de tu vida, ya que la apatía que presentas te puede impedir que enfrentes adecuadamente todas las situaciones que se presentan en la vida cotidiana. La depresión si no es atendida a tiempo puede provocar la muerte del paciente. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }
    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  bipolaridadEval(questions: any) {
    const positives = this.getPositives(questions);

    if (positives > 0 && positives < 4) {
      return 'De acuerdo a los datos obtenidos no contamos con información suficiente para determinar que existe un probable Trastorno Bipolar, sin embargo pueden existir cambios en tus hábitos o conductas de los que te recomendamos que realices un diagnóstico. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (positives >= 4) {
      return 'De acuerdo con los datos obtenidos, es probable que presentes un Trastorno Bipolar; es necesario que busques ayuda y tratamiento de inmediato, para mejorar tu salud física, tu economía y la relación con tus seres queridos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    }else if (positives === 0){
      return 'De acuerdo a los datos obtenidos no contamos con información suficiente para determinar que existe un probable Trastorno Bipolar. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }
    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  muerteDeUnSerQueridoEval(questions: any) {
    const positivesArray = this.getArraysOfPositives(questions);
    const setOne = [6, 10, 11];
    const setTwo = [1, 3, 4, 7, 8, 9];
    const setComodin = [2, 5];

    const intersectionOne = _.intersection(setOne, positivesArray);
    const intersectionTwo = _.intersection(setTwo, positivesArray);
    const intersectionComidin = _.intersection(setComodin, positivesArray);

    if (positivesArray.length === 11) {
      return 'De acuerdo con las respuestas obtenidas, es muy probable que el proceso de duelo que presentas no ha seguido un proceso satisfactorio, es muy difícil aceptar la pérdida del ser querido; posiblemente tu dolor sea tan grande que te impida continuar con tu vida. Esto puede bloquear tu desarrollo y llevarte a una probable depresión severa que pueda poner en riesgo tu salud física y emocional. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría obre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    } else {

      if (intersectionOne.length == 3 && (intersectionTwo.length == 0 && intersectionComidin.length > 1)) {
        return 'De acuerdo con el puntaje obtenido, el duelo se ha ido procesando, y en este caso, lo que presentes es una añoranza al ser querido, sabes que siempre estará en tu historia de vida, pero puedes continuar sin que el dolor sea una limitante. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
      }

      if (intersectionTwo.length == 6 && (intersectionOne.length == 0 && intersectionComidin.length > 1)) {
        return 'De acuerdo con las respuestas obtenidas, es muy probable que el proceso de duelo que presentas no ha seguido un proceso satisfactorio, es muy difícil aceptar la pérdida del ser querido; posiblemente tu dolor sea tan grande que te impida continuar con tu vida. Esto puede bloquear tu desarrollo y llevarte a una probable depresión severa que pueda poner en riesgo tu salud física y emocional. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría obre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
      }
      return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
    }
  }

  ansiedadEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount <= 4) {
      return 'De acuerdo con los datos recibidos, es probable que no presentes un Trastorno de Ansiedad como tal, sin embargo, te recomendamos que atiendas tu salud emocional y que busques alternativas para liberarte del estrés, ya que los síntomas que presentas pueden empeorar, y entonces puede existir la posibilidad de desarrollar dicho padecimiento. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes otra duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount > 4) {
      return 'De acuerdo con los datos recibidos, es probable que presentes un Trastorno de Ansiedad. Te recomendamos que atiendas tu salud emocional y que busques ayuda profesional ya que los síntomas que presentas si no son atendidos oportunamente, pueden empeorar y entonces afectar tu vida en el área de pareja, familia, laboral y social. Este padecimiento incluso, te puede impedir salir de tu casa y realizar tus actividades cotidianas. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  anorgasmiaEval(questions: any) {
    return 'lol';
  }

  drogadiccionEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount > 0) {
      return 'De acuerdo con los datos obtenidos es probable que presentes un problema de adicción, la cual es una enfermedad mortal que crece día con día y que si no se atiende a tiempo puede interferir con tu pleno desarrollo, así como terminar con tu vida y con la de tus seres queridos. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Toda la información que se genere será totalmente confidencial. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    } else {
      return 'De acuerdo con las respuestas obtenidas, no reúnes los criterios necesarios para determinar que padeces alguna adicción, sin embargo, incluso el consumo experimental y ocasional de cualquier sustancia psicotrópica puede ser un puente hacia la adicción, la cual puede terminar con tu vida; recuerda que la prevención es el mejor tratamiento. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros, nuestra comunicación será estrictamente confidencial.';
    }
  }

  alcoholismoEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount <= 2) {
      return 'De acuerdo a los resultados obtenidos no existen los elementos necesarios para determinar que presentes problemas con tu forma de beber, sin embargo, el consumo periódico de alcohol te puede generar la dependencia a la sustancia, y sin que te des cuenta, esto te puede provocar la adicción a una de las enfermedades mortales más destructivas: el Alcoholismo. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount >= 3) {
      return 'De acuerdo con los datos obtenidos es probable que presentes problemas con tu forma de beber, lo cual puede incrementar y provocarte severos daños físicos y emocionales. El tratamiento y recuperación del alcoholismo requieren de un gran compromiso por parte del paciente; existen diversas alternativas de tratamiento que pueden ayudar a salir adelante, así como a impedir que el alcoholismo acabe con tu vida y con la de tus seres queridos. Es importante que rescates tu vida y busques apoyo profesional pronto, para evitar que este padecimiento mortal te aniquile. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  bulimiaEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount <= 2) {
      return 'De acuerdo con los resultados obtenidos, no se reúnen los criterios para diagnosticar un Trastorno  de la Conducta Alimentaría. Si consideras que tu forma de comer te ocasiona conflictos físicos o emocionales, es importante que busques apoyo profesional, para evitar que los síntomas incrementen y se ponga en riesgo tu vida. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount >= 3) {
      return 'De acuerdo con los resultados obtenidos, es probable que  presentes un Trastorno de Bulimia. Sabemos que la imagen corporal es muy importante para ti, sin embargo, si no atiendes a tiempo tu salud física y emocional, los síntomas pueden incrementar y poner en riesgo tu vida. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  anorexiaEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount <= 2) {
      return 'De acuerdo con los resultados obtenidos, no se reúnen los criterios para diagnosticar un Trastorno de la Conducta Alimentaría. Si consideras que tu forma de comer te ocasiona conflictos físicos o emocionales, te recomendamos que te comuniques con nosotros para que nuestros especialistas te brinden la orientación adecuada. De cualquier manera, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount >= 3) {
      return 'De acuerdo con los resultados que obtuviste, es probable que  presentes un Trastorno de Anorexia. Sabemos que la imagen corporal es muy importante para ti, sin embargo, si no atiendes a tiempo tu salud física y emocional, los síntomas pueden incrementar y poner en riesgo tu vida. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes alguna otra duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  violenciaSeresQueridosEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount < 3) {
      return 'Con base a la información recibida no se cuentan con elementos suficientes para realizar una valoración completa, sin embargo, si existen algunas situaciones que te produzcan conflicto, y angustia, te sugerimos que busques apoyo profesional para que obtengas información y asesoría. De cualquier manera, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a que te comuniques con nosotros';
    } else if (positivesCount > 2) {
      return 'Es probable que presentes problemas con tu AGRESIVIDAD, lo cual puede desencadenar problemas de violencia que pongan en riesgo la estabilidad y salud de tu familia. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas de tratamiento. Si tienes otra duda, te invitamos a comunicarte con nosotros';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  histrionicoEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount < 6) {
      return 'De acuerdo con los resultados obtenidos, no se cuentan con los elementos necesarios para determinar si padeces de un Trastorno de Personalidad Histriónico, en caso de que percibas que tengas problemas con tus relaciones y se te dificulte controlar tus emociones; te aconsejamos que nos llames para que nuestros especialistas te puedan informar y orientar al respecto. De cualquier manera, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    } else if (positivesCount >= 6) {
      return 'De acuerdo con los resultados obtenidos, es probable que presentes este trastorno, y por lo tanto que diversas áreas de tu vida se vean afectadas debido a la dificultad que presentas para controlar tus emociones; es importante atender a tiempo esta problemática para evitar que tus síntomas incrementen, y llegues a sentirte solo, incomprendido, y que presentes importantes conflictos en tus relaciones interpersonales. Te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico. Si tienes alguna duda, te invitamos a que te comuniques con nosotros.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }

  narcicistaEval(questions: any) {
    const positivesCount = this.getPositives(questions);

    if (positivesCount < 6) {
      return 'De acuerdo con los datos que obtenidos, no se cuenta con los elementos necesarios para determinar si padeces de un Trastorno de Personalidad Narcisista, en caso de que percibas que presentas problemas de relación con tus semejantes, y consideres que los demás no te entienden o no te valoran como quisieras, te aconsejamos que busques apoyo profesional y te comuniques con nosotros. De cualquier manera, te sugerimos que revises la valoración de tu caso (la cual te será enviada a tu correo electrónico), en donde nuestros especialistas te ofrecerán asesoría sobre este tema y te informarán sobre diversas alternativas para enfrentar tu situación, incluyendo el recibir apoyo psicológico.';
    } else if (positivesCount >= 6) {
      return 'De acuerdo con los resultados que obtuviste, es probable que presentes este trastorno, por las diversas áreas de tu vida tales como la de pareja, familia, social y laboral se puedan ver afectadas. Te pedimos que te comuniques con nosotros para que nuestros especialistas te puedan informar y orientar al respecto.';
    }

    return "'No tenemos elementos suficientes para poder determinar lo que sucede. En breve nos pondremos en contacto contigo por el mismo medio para platicar sobre tus dudas o la posible problemática por la que atraviesas, o bien, te invitamos a que te comuniques con nosotros, la llamada es gratuita.';";
  }
}


