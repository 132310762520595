<section class="section-title" style="background:url(../assets/images/bg-webinars2.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Talleres </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Talleres</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section style="background-color: #F5F8FA;">
    <div class="container webinars-cont">
        <div class="row">
            <div class="col-xl-3 col-lg-4 offset-xl-1">
                <div class="webinars">
                    <p style="margin-bottom:15px"><img src="../../../assets/images/icono-calendario.svg" width="20"><strong> Próximos Talleres</strong></p>
                    
                    <ul class="fechas">
                        <li *ngFor="let webinar of webinars" class="fecha">
                           <strong> {{ webinar.fecha}} </strong> | {{ webinar.tema }} - {{ webinar.ponente }} <span> {{ webinar.hora }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-7 col-lg-8  ">
                <div class="form-webinars">
                    <h2 class="titulo-1">Inscríbete  a nuestros Talleres</h2>
                    
                       
                        <form #f="ngForm" ngNativeValidate (ngSubmit)="addWebinar(f)" id="webinars">
                            <div class="form-row">

                                <div class="col-12">
                                    <label class="my-1 mr-2" for="selectEvent">Próximos Talleres</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" for="selectEvent"><img src="../../../assets/images/icono-calendario.svg" width="16"></div>
                                        </div>

                                        <select ngModel class="custom-select" name="selectEvent" required>


                                        
                                            <option selected value="">Selecciona...</option>
                                            <option *ngFor="let webinar of webinars" value="{{ webinar.tema }}"> {{ webinar.fecha}} | {{ webinar.tema }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-7 mb-1">
                                    <label for="nombre">Nombre</label>
                                    <input ngModel type="text" class="form-control" name="nombre" id="nombre" placeholder="Nombre"  required>
                                </div>
                                <div class="col-md-5 mb-1">
                                    <label for="lugar">Lugar de residencia</label>
                                    <input ngModel type="text" class="form-control" name="lugar" placeholder="¿Dónde resides?"  required>
                                </div>
                                <div class="col-md-8 mb-1">
                                    <label for="email">E-mail</label>
                                    <input ngModel type="email" class="form-control" name="email" placeholder="E-mail" required>
                                </div>
                                <div class="col-md-4 mb-1">
                                    <label for="edad">Edad</label>
                                    <input ngModel type="text" class="form-control" name="edad" placeholder="Edad" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    <input ngModel name="acepto" class="form-check-input" type="checkbox"  id="checkAcepto" required>
                                    <label class="form-check-label" for="checkAcepto">
                                        Acepto y estoy de acuerdo con el <a href="https://www.simisae.com.mx/aviso-legal">aviso legal</a> y el <a href="https://www.simisae.com.mx/aviso-de-privacidad">aviso de privacidad</a>
                                    </label>
                                </div>
                            </div>
                            
                        
                            <div *ngIf="success" class="alert alert-success mt-2 text-center" role="alert">
                                <img src="../../../assets/images/ico-success.svg" alt="Icono exito" width="30"><br>
                                <h4>¡Gracias por inscribirte!</h4>
                                Muy pronto recibirás un correo electrónico con el acceso directo a tu taller de interés
                            </div>
                            <button class="btn btn-uno"  type="submit" [disabled]="loading">
                                <span *ngIf="!loading; else x">¡Quiero inscribirme!</span>
                                <ng-template #x>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Enviando...
                                </ng-template>
                            </button>

                        </form>
                    
                </div>
            </div>
            
        </div>
    </div>
</section>