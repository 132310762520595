import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebinarsService {
  constructor(private http: HttpClient) { }
  
  getWebinars() {
    return this.http.get(
      "assets/data/webinars.json"
     
    );
  }

  getWebinars1() {
    return this.http.get(
      "assets/data/webinarsh.json"
     
    );
  }
}

