<section class="section-title" style="background:url(../assets/images/quienes-somos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page text-white"><h1>Quiénes somos</h1></div>

            </div>
            <div class="col-md-4">
                <div class="breadcrumbs ">
                    <p class="text-white"><a routerLink="/" class="text-white">Inicio &nbsp;/ &nbsp;</a> Consejos del
                        Dr. Simi</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row mt-xl mb-5">
        <div class="col-md-7">
            <h2 class="titulo-1 pb-4">Nosotros</h2>
            <h3 class="subtitle-1 pb-4">Somos un equipo de psicólogos egresados de distintas universidades, con diversas
                especialidades y un eficiente manejo de modelos terapéuticos.</h3>
            <p>Llevamos años brindando la asesoría y orientación psicológica de manera gratuita, está labor ha llevado a Víctor González Dr. SIMI a expandir la ayuda y llegar a todos aquellos que lo necesiten.<br><br>
            Crecimos y ahora somos Centro SIMI de Salud Emocional (SIMISAE) atendiendo y orientando sobre los diversos temas emocionales de manera gratuita por medio de una línea telefónica, chat y vía mail. Estos servicios están disponibles las 24 horas, los 365 días del año al teléfono 800-911-3232, en la que más de 110 psicólogos ofrecen un promedio de 120 mil atenciones al mes.<br><br>
                Al identificar la cantidad de personas que viven con estas problemáticas es que surge la línea de ayuda
                psicológica, para brindar asesoría, orientación, así como proporcionar información sobre diversas
                instituciones que ofrezcan el servicio frente a frente, cuidando que sean instancias profesionalizadas
                en la atención que se requiera.
            </p>
        </div>
        <div class="col-md-5">
            <img class="nosotros-img" src="assets/images/quienes-somos-1.jpg" width="100%" alt="">
        </div>
    </div>
</div>
<section class="seccion-mision">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3 class="titulo-1">Visión</h3>
                <p>Ser un organismo líder en el área de asesoría y orientación psicológica, especializada en la
                    atención, detección y acompañamiento terapéutico, que lleve a nuestra población a encontrar el
                    camino adecuado para su desarrollo emocional.</p>
            </div>
            <div class="col-md-6">
                <h3 class="titulo-1">Misión</h3>
                <p>Atender, orientar y guiar a la población en materia de psicología y referencia sobre las
                    instituciones que ofrecen el tratamiento adecuado que pueda desarrollar y potenciar las habilidades
                    y capacidades que lleven hacia el crecimiento emocional e integral.</p>
            </div>
        </div>
    </div>
</section>
<section class="seccion-objetivo" style="background: url(../assets/images/quienes-somos-bg2.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
                <div class="objetivo">
                    <h3 class="titulo-1 pb-3">Objetivos</h3>
                    <ul>
                        <li>Realizar una sugerencia oportuna sobre los padecimientos emocionales de la persona
                            interesada.</li>

                        <li>Brindar asesoría, orientación y referencia a diversas instituciones que ofrecen el
                            tratamiento
                            adecuado (a nivel nacional), cerca de tu localidad y de acuerdo a tu presupuesto.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>