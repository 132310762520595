import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InformateService {
  constructor(private http: HttpClient) {}

  getNotas() {
    return this.http.get(
      "assets/data/informate.json"
     
    );
  }

  getTest() {
    return this.http.get(
      "https://simisae.com.mx/assets/data/tests.json"
    );
  }
  // getNotaById(id: number) {
  //   return this.http
  //     .get('./assets/data/informate.json')
  //     .subscribe((resp: any) => {
  //        resp.find(nota => nota.id == id)
  //     })
  // }
}
