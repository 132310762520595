
<div class="container-fluid">
    <div class="row pt-5">
        <div class="col-12 text-right mb-3">
           
            <button class="btn btn-primary" routerLink="/administrador/gestor" >
                <span > ❮ Regresar</span>
            </button>
        </div>
        
        <div class="col-12 mx-auto datos-user">
            <div class="col-12 user-name pt-2 pb-2">
                <h2> <span class="status-user status-ok"></span>{{test?.nombreCompleto}}</h2>
            </div>
            
            <div class="row pt-2 pb-2">
                <div class="col-10 col-md-4">
                    <p><strong>Email: </strong> <span>{{test?.correo}} </span></p>
                </div>
                <div class="col-10 col-md-4">
                    <p><strong>Fecha: </strong> <span> {{test?.fechaInsercion |  date:'short'}} </span></p>
                </div>
                <div class="col-10 col-md-4">
                    
                </div>
            </div>
        </div>
    </div>
    <div class="row datos-user">
        <div class="col-12 mx-auto table-responsive "> 
            <table class="table">
                <thead class="thead">
                    <tr>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                    </tr>
                </thead>
                <tbody >              
                    <tr *ngIf="test?.pregunta1!" class="hover" [ngClass]="{'table-success': test.respuesta1 === 'si', 'table-success2' : test.respuesta1 != 'si' }">
                        <td >{{test?.pregunta1}}</td> <td >{{test?.respuesta1}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta2 != null" class="hover" [ngClass]="{'table-success': test.respuesta2 === 'si', 'table-success2' : test.respuesta2 != 'si' }">
                        <td >{{test?.pregunta2}}</td> <td >{{test?.respuesta2}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta3 != null" class="hover" [ngClass]="{'table-success': test.respuesta3 === 'si', 'table-success2' : test.respuesta3 != 'si' }">
                        <td >{{test?.pregunta3}}</td> <td >{{test?.respuesta3}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta4 != null" class="hover" [ngClass]="{'table-success': test.respuesta4 === 'si', 'table-success2' : test.respuesta4 != 'si' }">
                        <td >{{test?.pregunta4}}</td> <td >{{test?.respuesta4}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta5 != null" class="hover" [ngClass]="{'table-success': test.respuesta5 === 'si', 'table-success2' : test.respuesta5 != 'si' }">
                        <td >{{test?.pregunta5}}</td> <td >{{test?.respuesta5}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta6 != null" class="hover" [ngClass]="{'table-success': test.respuesta6 === 'si', 'table-success2' : test.respuesta6 != 'si' }">
                        <td >{{test?.pregunta6}}</td> <td >{{test?.respuesta6}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta7 != null" class="hover" [ngClass]="{'table-success': test.respuesta7 === 'si', 'table-success2' : test.respuesta7 != 'si' }">
                        <td >{{test?.pregunta7}}</td> <td >{{test?.respuesta7}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta8 != null" class="hover" [ngClass]="{'table-success': test.respuesta8 === 'si', 'table-success2' : test.respuesta8 != 'si' }">
                        <td >{{test?.pregunta8}}</td> <td >{{test?.respuesta8}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta9 != null" class="hover" [ngClass]="{'table-success': test.respuesta9 === 'si', 'table-success2' : test.respuesta9 != 'si' }">
                        <td >{{test?.pregunta9}}</td> <td >{{test?.respuesta9}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta10 != null" class="hover" [ngClass]="{'table-success': test.respuesta10 === 'si', 'table-success2' : test.respuesta10 != 'si' }">
                        <td >{{test?.pregunta10}}</td> <td >{{test?.respuesta10}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta11 != null" class="hover" [ngClass]="{'table-success': test.respuesta11 === 'si', 'table-success2' : test.respuesta11 != 'si' }">
                        <td >{{test?.pregunta11}}</td> <td >{{test?.respuesta11}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta12 != null" class="hover" [ngClass]="{'table-success': test.respuesta12 === 'si', 'table-success2' : test.respuesta12 != 'si' }">
                        <td >{{test?.pregunta12}}</td> <td >{{test?.respuesta12}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta13 != null" class="hover" [ngClass]="{'table-success': test.respuesta13 === 'si', 'table-success2' : test.respuesta13 != 'si' }">
                        <td >{{test?.pregunta13}}</td> <td >{{test?.respuesta13}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta14 != null" class="hover" [ngClass]="{'table-success': test.respuesta14 === 'si', 'table-success2' : test.respuesta14 != 'si' }">
                        <td >{{test?.pregunta14}}</td> <td >{{test?.respuesta14}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta15 != null" class="hover" [ngClass]="{'table-success': test.respuesta15 === 'si', 'table-success2' : test.respuesta15 != 'si' }">
                        <td >{{test?.pregunta15}}</td> <td >{{test?.respuesta15}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta16 != null" class="hover" [ngClass]="{'table-success': test.respuesta16 === 'si', 'table-success2' : test.respuesta16 != 'si' }">
                        <td >{{test?.pregunta16}}</td> <td >{{test?.respuesta16}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta17 != null" class="hover" [ngClass]="{'table-success': test.respuesta17 === 'si', 'table-success2' : test.respuesta17 != 'si' }">
                        <td >{{test?.pregunta17}}</td> <td >{{test?.respuesta17}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta18 != null" class="hover" [ngClass]="{'table-success': test.respuesta18 === 'si', 'table-success2' : test.respuesta18 != 'si' }">
                        <td >{{test?.pregunta18}}</td> <td >{{test?.respuesta18}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta19 != null" class="hover" [ngClass]="{'table-success': test.respuesta19 === 'si', 'table-success2' : test.respuesta19 != 'si' }">
                        <td >{{test?.pregunta19}}</td> <td >{{test?.respuesta19}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta20 != null" class="hover" [ngClass]="{'table-success': test.respuesta20 === 'si', 'table-success2' : test.respuesta20 != 'si' }">
                        <td >{{test?.pregunta20}}</td> <td >{{test?.respuesta20}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta21 != null" class="hover" [ngClass]="{'table-success': test.respuesta21 === 'si', 'table-success2' : test.respuesta21 != 'si' }">
                        <td >{{test?.pregunta21}}</td> <td >{{test?.respuesta21}}</td>
                    </tr>
                    <tr *ngIf="test?.pregunta22 != null" class="hover" [ngClass]="{'table-success': test.respuesta22 === 'si', 'table-success2' : test.respuesta22 != 'si' }">
                        <td >{{test?.pregunta22}}</td> <td >{{test?.respuesta22}}</td>
                    </tr>
                    <!-- <tr *ngIf="test?.pregunta23? != null" class="hover" [ngClass]="{'table-success': test.respuesta23 === 'si', 'table-success2' : test.respuesta23 != 'si' }">
                        <td >{{test?.pregunta23}}</td> <td >{{test?.respuesta23}}</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="col-10 mx-auto">
            <p><strong>Mensaje: </strong></p>
            <p>{{test?.comentarios}}</p>
        </div>
        <div class="col-10 mx-auto">
            <p><strong>Respuesta: </strong></p>
            <p>{{test?.respuestaTest}}</p>
        </div>
        <div class="col-10 mx-auto">
            <form ngNativeValidate #f="ngForm" (ngSubmit)="sendAndShowEval(f); f.reset()">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="estado">Psicologo</label>
                        <select ngModel name="psicologo" name="psicologo" class="form-control" required>
                            <option>Escoge una opción</option>
                            <option *ngFor="let item of list" [value]="item.email">{{item.nombre}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4 pt-4">
                        <button class="btn btn-primary" type="submit">
                           Enviar
                        </button>
                    </div>
                </div>
            </form>
            <hr>
        </div>
       
        <div class="col-12 col-md-3 mx-auto text-center pb-4">
            
            <button class="btn btn-danger ml-3 w-100"  (click)="delete()">
                Eliminar
            </button>
        </div>
    </div>
</div>
