<section class="section-title" style="background:url(../assets/images/contacto-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Psicólogos en línea </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Psicólogos en línea</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row pt-5 pb-5">
        <div class="col-md-8 offset-md-2 mb-5">
            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/opZgS7G3uM8?rel=0&autoplay=1&loop=1&fs=1&controls=1&playlist=opZgS7G3uM8" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-12 text-center ">
            <h2 class="titulo-1 mb-5">Asesorias gratuitas</h2>
        </div>
        <div class="col-md-4 text-center asesorias-item">
            <a href="tel:8009116666">
                <div class="asesorias-item-icon">
                    <img src="assets/images/icons/icono-telefono.png" alt="icono teléfono">
                </div>
                <h3 class="titulo-2">800 911 32 32</h3>
                <p>24 Hrs. - 365 días.</p>
            </a>
        </div>
        <div class="col-md-4 text-center asesorias-item">
            <a class="chat-btn" (click)=toggleDisplay()>
                <div class="asesorias-item-icon">
                    <img src="assets/images/icons/icono-chat.png" alt="icono chat">
                </div>
                <h3 class="titulo-2 ">Chat</h3>
                <p class="">24 Hrs. - 365 días.</p>
            </a>
        </div>
        <div class="col-md-4 text-center asesorias-item">
            <a href="mailto:centrodiagnostico@simisae.com.mx">
                <div class="asesorias-item-icon">
                    <img src="assets/images/icons/icono-email.png" alt="icono mail">
                </div>
                <h3 class="titulo-2">Email</h3>
                <p>centrodiagnostico@simisae.com.mx</p>
            </a>
        </div>
    </div>
</div>
<section class="section-drSimi">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-6 ">
                <img class="frase" src="assets/images/frase.svg">
            </div> -->
            
            <div class="col-lg-6 m-auto">
                <div class="preguntas-frecuentes-home">
                    <h3 class="titulo-1 text-light text-center">preguntas frecuentes</h3>
                    <p>Todas las preguntas principales relacionadas con la consulta, servicio y mucho más.</p>
                    <accordion [closeOthers]="oneAtATime">
                        <accordion-group heading="¿Su servicio es confidencial?">
                            <p>Sí; al inicio de la sesión te damos a conocer nuestro aviso de privacidad lo cual te brinda la seguridad de que tus datos personales estarán protegidos; puedes consultarlo en la siguiente liga: https://simisae.com.mx/aviso-de-privacidad</p>
                            <p>Aunado a ello, solo pediremos tu nombre y edad para tener información que nos ayude a la orientación psicológica, es importante mencionar que todos nuestros psicólogos son profesionales y la secrecía es parte de nuestra ética.</p>                        
                        </accordion-group>
                        <accordion-group heading="¿El servicio de ayuda psicológica tiene algún costo?">
                            <p>No, gracias a la visión altruista de nuestro fundador, el C.P. Victor González Torres (Dr. Simi), el servicio que ofrece SIMISAE es completamente gratuito.</p>
                        </accordion-group>
                        <accordion-group heading="¿Dónde puedo encontrarlos?">
                            <p>Puedes encontrarnos en los siguientes canales de atención:</p>
                            <p>Vía telefónica y WhatsApp a nuestro número 800 911 3232</p>
                            <p>Por medio de nuestro correo electrónico <strong>centrodiagnostico@simisae.com.mx </strong></p>
                            <p>Enviándonos un mensaje a nuestro Facebook o Instagram <strong>https://www.facebook.com/SIMISAE </strong>y <strong>@simisaemx</strong></p>
                            <p>Si prefieres ser atendido de forma presencial, ponemos a tu alcance el contacto de</p>
                        </accordion-group>
                        <accordion-group heading="¿Quién puede solicitar ayuda?">
                            <p>Todo aquel que necesite el apoyo psicológico, la persona que esté atravesando por un duelo, problemas para relacionarse, adicciones, depresión, ansiedad o ideación suicida, en fin, cualquier situación que esté afectando emocionalmente a la persona.</p>
                        </accordion-group>
                        
                    </accordion>
                    <br>
                    
                    <a routerLink="/preguntas-frecuentes" class="btn-naranja">Ver Más</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section style="background: #F5F8FA">
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5 text-center mb-4">
                <h2 class="titulo-1 pb-4">Escríbenos</h2>
                <p>Encuentra con nosotros la escucha y orientación que requiere la situación que enfrentas. Nuestros
                    psicólogos expertos te contestarán, asesorándote o bien sugiriéndote diversas instituciones que te
                    pueden ofrecer el servicio adecuado a la problemática que vivas (a nivel nacional) cerca de tu
                    localidad y de acuerdo con tu presupuesto.</p>
                <p>¡Gracias por tu confianza!</p>
            </div>
            <div class="col-12 mb-5 pb-5">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" ngNativeValidate>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputNombre">Nombre</label>
                            <input ngModel name="nombre" type="text" class="form-control" id="inputNombre" required>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Email</label>
                            <input ngModel name="email" type="email" class="form-control" id="inputEmail4" required>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputTelefono">Teléfono</label>
                            <input ngModel name="telefono" class="form-control" id="inputTelefono" pattern="\d+"
                            maxlength="10"
                            type="text"
                            placeholder="Teléfono (10 dígitos)"
                                required>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEstado">Estado</label>
                            <select ngModel name="estado" id="inputEstado" class="form-control" required>
                                <option value=" ">Selecciona un Estado</option>
                                <option value='1'>Aguascalientes </option>
                                <option value='2'>Baja California Nte </option>
                                <option value='3'>Baja California Sur </option>
                                <option value='4'>Campeche </option>
                                <option value='7'>Chiapas </option>
                                <option value='8'>Chihuahua </option>
                                <option value='5'>Coahuila </option>
                                <option value='4'>Colima </option>
                                <option value='9'>Ciudad de México </option>
                                <option value='10'>Durango </option>
                                <option value='15'>Edo de Mex </option>
                                <option value='11'>Guanajuato </option>
                                <option value='12'>Guerrero </option>
                                <option value='13'>Hidalgo </option>
                                <option value='14'>Jalisco </option>
                                <option value='16'>Michoacan </option>
                                <option value='17'>Morelos </option>
                                <option value='18'>Nayarit </option>
                                <option value='19'>Nuevo Leon </option>
                                <option value='20'>Oaxaca </option>
                                <option value='21'>Puebla </option>
                                <option value='22'>Queretaro </option>
                                <option value='23'>Quintana Roo </option>
                                <option value='24'>San Luis Potosi </option>
                                <option value='25'>Sinaloa </option>
                                <option value='26'>Sonora </option>
                                <option value='27'>Tabasco </option>
                                <option value='28'>Tamaulipas </option>
                                <option value='29'>Tlaxcala </option>
                                <option value='30'>Veracruz </option>
                                <option value='31'>Yucatan </option>
                                <option value='32'>Zacatecas </option>
                            </select>
                        </div>
                        <div class="form-group col-12">
                            <label for="textareaMensaje">Mensaje</label>
                            <textarea ngModel name="mensaje" class="form-control" id="textareaMensaje" rows="4"
                                required></textarea>
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input ngModel name="acepto" class="form-check-input" type="checkbox" id="checkAcepto"
                                    required>
                                <label class="form-check-label" for="checkAcepto">
                                    Acepto y estoy de acuerdo con el <a
                                        href="https://www.simisae.com.mx/aviso-legal">aviso legal</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button class="btn-main btn-uno" style="margin: 0;" type="submit" [disabled]="loading">
                        <span *ngIf="!loading; else x">Enviar</span>
                        <ng-template #x>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Enviando...
                        </ng-template>
                    </button>
                    <div *ngIf="success" class="alert alert-success mt-2" role="alert">
                        Enviado
                    </div>
                </form>
            </div>
        </div>
    </div>
</section> -->


