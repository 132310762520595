import { Component, OnInit } from '@angular/core';
import { WebinarsService } from 'src/app/services/webinars.service';
import { NgForm } from '@angular/forms';
import { SimisaeService } from "src/app/services/simisae.service";
@Component({
  selector: 'app-cancela-inscripcion',
  templateUrl: './cancela-inscripcion.component.html',
  styleUrls: ['./cancela-inscripcion.component.css']
})
export class CancelaInscripcionComponent implements OnInit {
  loading: boolean | undefined
  success: boolean | undefined
  danger: boolean | undefined
  webinars: any;
  constructor(
    private webinarService: WebinarsService,
    private _simiSAE: SimisaeService
  ) { 
    this.webinarService.getWebinars().subscribe((resp: any) => {
      this.webinars = resp
    })
  }

  ngOnInit(): void {
  }
  cancelar(fcancela: NgForm) {
    this.loading = true
    
      var evento=fcancela.value.evento;
      
     var email=fcancela.value.email;

     this._simiSAE.deleteWebinar(email,evento).subscribe((resp: any) => {
      if(resp.ok == true){
			  this.success = true
			  this.loading = false 
        fcancela.reset()
        setTimeout(()=>{                           
          this.success = false;
        }, 8000);
      }
      if(resp.error == true)
      {
        this.danger=true
        this.loading = false

      }
      
		});
     

    }

   
  }

