<section class="section-title" style="background:url(../assets/images/quienes-somos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> CONSULTORIOS SIMIAPSE </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Consultorios</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section style="background-color: #f8f8f8;">
    <div class="container">
        <div class="row">
           
            <div class="col-md-7 pt-5">
                <div class="card-main">
                    <img src="../../../../assets/images/logo-SIMIAPSE.svg" class="logo-simiapse" >
                    <p>Es el nuevo servicio de atención psicológica </p>
                    <p class="bg-verde">¡PRESENCIAL y GRATUITO!</p>
                    <p>Trabajamos bajo el modelo de terapia breve y con herramientas prácticas que te permitan mejorar tu salud mental y emocional.</p>
                    <p> <strong>¡Agenda tu cita por &nbsp; <a href="tel:8009113232"> <img src="../../../assets/images/icons/phone_icon.svg" width="18" alt=""> llamada</a>&nbsp; o por mensaje de<a href="tel:8009113232" style="color: #00b522;">  whatsapp <img src="../../../assets/images/iconowhatsapp.png" width="26"></a>!</strong></p>
                    
                </div>
            </div>
            <div class="col-md-4 ">
                <img src="../../../../assets/images/terapia.jpg" class="img-terapia">
            </div>          
            <div class="col-12 mt-5">
                <h2>Conoce nuestras ubicaciones</h2>
            </div>          
        </div>

        <div id="Fmap" class="row">
            <div class="col-12 map-cont"> 
                
                <form class="row">
                    <div class="col-md-6">
                        <label for="correo">ESTADO</label>
                        <select ngModel="estado" name="estadoId" (ngModelChange)="getCiudades($event);clearInput($event)"
                            class="form-control" required>
                            <option [ngValue]=""  selected disabled>Seleccione Estado</option>
                            <option *ngFor="let estado of estados" [ngValue]="estado.idestado"> {{ estado.nombre | uppercase}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label for="correo">MUNICIPIO O ALCALDÍA</label>
                        <select ngModel="ciudad" name="ciudadId" (ngModelChange)="selectCiudad($event)"
                            class="form-control" required (click)="activo='seleccionado'">
                            <option [ngValue]=""  selected disabled>Seleccione una Municipio o Alcaldía</option>
                            <option *ngFor="let ciudad of ciudades" [ngValue]="ciudad"> {{ ciudad.nombre | uppercase}}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="cttInp dCuatro dmDoce" *ngIf="this.okmun">
                        <label for="correo">Sucursal</label>
                        <select ngModel="sucursal" name="sucursalId" (ngModelChange)="getprocedimientos($event)"
                            class="form-control" required>
                            <option [ngValue]=""  selected>Seleccione una Sucursal</option>
                            <option *ngFor="let consultorio of consultorios" [ngValue]="consultorio"> {{ consultorio.sucursal | uppercase}}
                            </option>
                        </select>
                    </div> -->
                </form>
            

                
                <div class="dDiez dmDoce FtbRst flx"  *ngIf="this.espec">
                    <p *ngIf="dataesp.calle?.length > 0">Calle: {{dataesp.calle}}</p>
                    <p *ngIf="dataesp.numeroExterior?.length > 0">Número Exterior: {{dataesp.numeroExterior}}</p>
                    <p *ngIf="dataesp.numeroInterior?.length > 0">Número Interior: {{dataesp.numeroInterior}}</p>
                    <p *ngIf="dataesp.codigoPostal?.length > 0"> Código Postal: {{dataesp.codigoPostal}}</p>
                </div>
                <div class="bg-warning p-2"  *ngIf="this.dont">
                    <p class="m-0">Por el momento no tenemos cobertura en su zona</p>
                </div>
                <!-- <button type="button" class="btn enviar"><a href="#">CONSULTAR COSTOS</a></button> -->
            
                    
                
            
    
            <!--Aquí va el mapa-->

                <div class="">
                    <div class="map-container">
                        <div *ngIf="!geopotitonok" class="mapCover"></div>
                        <div id="mapnu" class="map-card"></div>
                    </div> 
                </div>
            <!------->

                <div class="FlstDr">
                    <table class="Ftabla dOcho dtDiez dmOnce">
                        <!-- <tr id="tbTitle" class="tbTitle" [ngStyle]="this.showpro === true ? {'display': 'flex'}:{'display': 'none'}">
                            <th class="dSeis">TIPO DE SERVICIO</th>
                            <th class="dSeis">PRECIO</th>
                        </tr> -->
                    </table>
                    <!-- <table class="Ftabla dOcho dtDiez dmOnce" *ngIf="this.showpro">    
                        <tbody *ngFor="let procedimiento of procedimientos">
                            <tr>
                                <td [ngStyle]="{'background-color':getColor(procedimiento.tecnica)}">{{procedimiento.tecnica}}</td>
                                <td>{{procedimiento.precio | currency}}</td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                    <p id="Fresponsable" class="Fresponsable" [ngStyle]="this.showpro === true ? {'display': 'flex'}:{'display': 'none'}">Responsable sanitario: Dr. Ángel Gracia Ramírez Céd. Prof: 0753913-UNAM</p> -->
                </div>
            </div>  
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2 bg-gray">
                <img src="../../../../assets/images/target.svg" width="50" class="target">
                <p><strong>Objetivo: </strong><br>Contribuir al bienestar individual/colectivo y a la prevención de trastornos mentales, brindando terapia psicológica <strong>GRATIS</strong> a las personas que menos tienen.</p>
                                                  
                
            </div>
            <div class="col-lg-8 offset-lg-2 infografia">
                
                <h4 class="text-center text-dark"><small><span style="color: red; font-size: 90%;">MITOS</span> & <span style="color: rgb(5, 153, 11); font-size: 90%;"> REALIDAD</span> DE LA</small> <br><span>PSICOTERAPIA</span></h4>
            
                <div class="bg-azul">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../../assets/images/tache-icono.svg">
                        </div>
                        <div class="col-7">
                            <p><span style="color: red;">MITO: </span> La terapia es para “locos”.</p>
                        </div>
                        <div class="col-3">
                            <img src="../../../../assets/images/loco.png" class="icono-uno">
                        </div>
                    </div>
                </div>
                <div class="bg-none">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../../../assets/images/familia.gif" class="icono-dos">
                        </div>
                        <div class="col-9">
                            <p><span style="color: rgb(0, 199 ,38);">REALIDAD: </span>La terapia es para todas las personas que quieran mejorar su salud emocional</p>
                        </div>
                       
                    </div>
                </div>
                <!--
                <div class="bg-azul">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../../assets/images/tache-icono.svg">
                        </div>
                        <div class="col-7">
                            <p>Los psicólogos “leen la mente” o “son adivinos”</p>
                        </div>
                        <div class="col-3">
                            <img src="../../../../assets/images/adivino.png" class="icono-tres">
                        </div>
                    </div>
                </div>
                
                <div class="bg-none">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../../../assets/images/dialogo.gif" class="icono-dos">
                        </div>
                        <div class="col-9">
                            <p>Los psicólogos realizan una serie de preguntas y a veces utilizan alguna prueba o test para conocer la problemática</p>
                        </div>
                       
                    </div>
                </div>
                -->
                <div class="bg-azul">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../../assets/images/tache-icono.svg">
                        </div>
                        <div class="col-7">
                            <p><span style="color: red;">MITO: </span> Sólo si estás “muy mal” debes ir a terapia</p>
                        </div>
                        <div class="col-3">
                            <img src="../../../../assets/images/estar-mal.png" class="icono-cinco">
                        </div>
                    </div>
                </div>
                <div class="bg-none">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../../../assets/images/enojo.gif" class="icono-dos">
                        </div>
                        <div class="col-9">
                            
                            <p><span style="color: rgb(0, 199 ,38);">REALIDAD: </span>Puedes ir a terapia ante cualquier problema de la vida cotidiana en el que consideres que necesitas ayuda, o simplemente si buscas equilibrio mental y un mejor manejo de las emociones.</p>
                        </div>
                       
                    </div>
                </div>
                <div class="bg-azul">
                    <div class="row">
                        <div class="col-2">
                            <img src="../../../../assets/images/tache-icono.svg">
                        </div>
                        <div class="col-7">
                            <p><span style="color: red;">MITO: </span> El psicólogo te da consejos o “recetas” sobre cómo resolver los problemas</p>
                        </div>
                        <div class="col-3">
                            <img src="../../../../assets/images/receeta.gif" class="icono-siete">
                        </div>
                    </div>
                </div>
                <div class="bg-none">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../../../assets/images/psicologo.png" class="icono-cinco">
                        </div>
                        <div class="col-9">
                            <p><span style="color: rgb(0, 199 ,38);">REALIDAD: </span>El psicólogo te acompaña y orienta de manera respetuosa en los cambios y decisiones que quieras tomar</p>
                        </div>
                       
                    </div>
                </div>


            </div>
        </div>

    </div>
</section>