import { Component, OnInit } from '@angular/core';
import { SimisaeService } from '../../../services/simisae.service'
import { ActivatedRoute, Router } from '@angular/router'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-testdetalle',
  templateUrl: './testdetalle.component.html',
  styleUrls: ['./testdetalle.component.css']
})
export class TestdetalleComponent implements OnInit {
	test!: any;
	idsend: any;
	list!: any[];
	correoreceptor : string | undefined;
	receptor: any;
	


	constructor(
		private activatedRoute: ActivatedRoute,
		private _simiSAE: SimisaeService,
		private router: Router
		) { 
		if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
			this.router.navigate(['/administrador/login']);
		}
	}

	sendAndShowEval(data: NgForm ){
		this.correoreceptor = data.value.psicologo;
		this.list.forEach( (value) => {
			if(value.email ==  this.correoreceptor){
				this.receptor = value.nombre
			}
		});

		this._simiSAE.UpdateTest(this.idsend, this.receptor ,this.correoreceptor, sessionStorage.getItem('username'), sessionStorage.getItem('token')).subscribe((resp: any) => {
			if(resp.ok == true){
			this.router.navigate(['/administrador/testsporasignar']);
			}
		})

	}

	delete(){
		this._simiSAE.DeleteTest(this.idsend, sessionStorage.getItem('token')).subscribe((resp: any) => {
			if(resp.ok == true){
				this.router.navigate(['/administrador/testsporasignar']);
			}
		})
	}

	ngOnInit(): void {
		this.activatedRoute.params.subscribe(param => {
			param.id
			this._simiSAE.GetDetalle(sessionStorage.getItem('token'), param.id).subscribe((resp: any) => {
				
				this.idsend=resp.id;
				this.test = resp;
				this._simiSAE.GetPsicologos(sessionStorage.getItem('token')).subscribe((resp: any) => {
					this.list = resp;
					
				})
			})
		})
	
	}

}
