<!--
<div [ngClass]="{'main-banner': supportweb === true, 'main-banner-no-web' : supportweb != true }">
    <div class="container text-center">
        <h1 class="titulo-banner">SIMISAE<br><small>Centro SIMI de Salud Emocional</small></h1>
        <br>
        <carousel [noPause]="false">
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> se alcanza cuándo entendemos que lo único que controlamos son nuestras decisiones, no las de los demás
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> es pensar en ayudar, no importa si es una persona o mil, el número no importa
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> es saber que has ayudado a alguien a crecer. Puede ser en lo emocional, espiritual, laboral o económico
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;"></strong> Alguna vez te has preguntado ¿Cuántas personas te recordaran con amor y alegría cuándo ya no estés en este mundo?
                </p><br>
            </slide>
            <slide>
                <p> <strong style="color: #000; font-weight: 600;">La felicidad</strong> implica saber y aceptar que todo tiene un tiempo
                </p><br>
            </slide>
        </carousel>
       <button class="btn-main btn-uno" routerLink="/consejos-del-dr-simi">Atrévete a ser feliz</button>
    </div>
</div>
-->
<div class="container-fluid">
    <div class="row">
       


        <swiper [config]="configPrincipal" (swiper)="automatico($event)" >
            <ng-template swiperSlide *ngFor="let baner of baners">

                <a href="{{ baner.link }}" target="{{ baner.target }}">
                    <img src="{{ baner.imageUno }}" class="img-fluid desk">
                    <img src="{{ baner.imageMovil }}" class="img-fluid movil">
                    
                </a>

                
            </ng-template>
        </swiper>

    </div>
</div>
<!---
<section class="pb-5 seccion1">
    <div class="row">
        <div class="col-12">
            <a routerLink="/talleres">
                <img src="assets/images/banner-horizontal-talleres.jpg" class="img-fluid desk">
                <img src="assets/images/banner-horizontal-talleres-movil.jpg" class="img-fluid movil">
            </a>
        </div>
        
        <div class="col-lg-4 col-md-6 offset-lg-2">
            <picture>
                <source type="image/webp" srcset="assets/images/serFelizSiSePuede.webp">
                <source type="image/jpeg" srcset="assets/images/serFelizSiSePuede.jpg">
                <img src="assets/images/serFelizSiSePuede.jpg" alt="SIMISAE" class="img-victor-gonzalez">
            </picture>
        </div>
        
        <div class="col-lg-4 col-md-6 pt-5">
           
            <h1 class="titulo-consejos pb-2 ">10 consejos del<br><span>Dr. Simi para ser feliz</span></h1>
           
            <h5 class="text-center pb-2"> “La felicidad se percibe como algo inalcanzable, como un sueño y esto no es así.
                La
                felicidad
                es la satisfacción que tengas de tu propia vida, logros, metas, amores, encuentros y deseos.”</h5>
                <a class="btn-main btn-uno" routerLink="/consejos-del-dr-simi"> Haz clic</a>
        </div>
       
        <div class="col-xl-4 col-md-6">
            <div class="webinars">
                
                <div class="text-webinar">
                    <p style="margin-bottom:6px"><img src="../../../assets/images/icono-calendario.svg" width="20"> ¡Únete a nuestros</p>
                    <h2>Talleres!</h2>
                    <p>Y hablemos de los temas más relevantes sobre salud emocional.</p>
                    <br>
                    <a class="btn-main btn-uno" routerLink="/talleres">¡Inscríbete!</a>
                </div>
            </div>
        </div>
        
        <div class="col-xl-4 col-md-6">
            <div class="webinars-talleres">
                <div class="text-webinar">
                    <p style="margin-bottom:6px"> </p>
                    <h3><strong>Día mundial de la salud mental 2023</strong></h3>
                    <hr>
                    <h4>10 de octubre</h4>
                    <p>Inscríbete a nuestros talleres gratuitos en línea</p>
                    
                    <a class="btn-talleres " routerLink="/talleres-inscripcion">¡Regístrate!</a>
                </div>
                  
            </div>
        </div>
        
    </div>
</section>
-->
<section class="section-asesorias">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center ">
                <h2 class="titulo-1 mb-5"><a routerLink="/psicologos-en-linea" class="text-dark">Ayuda Psicológica Gratuita</a></h2>
            </div>
            <div class="col-md-4 text-center asesorias-item">
                <a href="tel:8009116666">
                    <div class="asesorias-item-icon">
                        <img src="assets/images/icons/icono-telefono.png" alt="icono teléfono">
                    </div>
                    <h2 class="titulo-2">Teléfono</h2>
                    <p>800 911 32 32. <br>24 Hrs. - 365 días.</p>
                </a>
            </div>
            <div class="col-md-4 text-center asesorias-item">
                <a class="chat-btn" (click)=toggleDisplay()>
                    <div class="asesorias-item-icon">
                        <img src="assets/images/icons/icono-chat.png" alt="icono chat">
                    </div>
                    <h2 class="titulo-2 ">Chat</h2>
                    <p class="">24 Hrs. - 365 días.</p>

                </a>
            </div>
            <div class="col-md-4 text-center asesorias-item">
                <a href="mailto:centrodiagnostico@simisae.com.mx">
                    <div class="asesorias-item-icon">
                        <img src="assets/images/icons/icono-email.png" alt="icono mail">
                    </div>
                    <h2 class="titulo-2">Email</h2>
                    <p>centrodiagnostico@simisae.com.mx</p>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-programas">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center pb-4">
                <h3 class="titulo-1">Disfruta de nuestros programas</h3>
            </div>
            <div *ngFor="let video of videos" class="col-md-3 col-sm-6 col-6">
                <a [href]="sanitize(video.url)" class="programas-item" target="_blank">
                    <div class="programas-item-img">
                        <img [lazyLoad]="'https://i.ytimg.com/vi/' + video.thumb +'/hqdefault.jpg '"  [useSrcset]="true" alt="">
                        <img class="ico-play" src="assets/images/ico-play.svg">
                    </div>
                    <h4 class="titulo-3 pt-1">{{ video.nombre }}</h4>
                    <p>{{ video.cuando }}</p>
                </a>
            </div>
        </div>
    </div>
</section>
<section class="section-newsletter" [ngClass]="{'section-newsletter': supportweb === true, '  section-newsletter-no-web' : supportweb != true }">
    <div class="container container-test">
        <div class="row">
            <div class="col-12 text-white text-center">
                <h3 class="titulo-1 text-white">Conócete</h3>
                <p>En el siguiente listado encontrarás varias pruebas, las cuales no tienen un fin
                    diagnóstico, pero te ayudarán a arrojar luces sobre la existencia de un eventual problema
                    psicológico, de modo de orientarte a buscar ayuda cuando sea necesario. No esperes más. </p>
                
                <div class="col-12">
                    <swiper [config]="configDos" (swiper)="automatico($event)" >
                        <ng-template swiperSlide *ngFor="let slide of slides">
                            <div class="cuestionario-home-content" routerLink="{{ slide.link }}" [style.background]="'url(../assets/images/cuestionarios/'+ slide.image +')'">
                                <div class="cuestionario-gradient"></div>
                                <div class="cuestionario-home">
                                    <p>{{ slide.cuestionario }}</p>
                                    
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                    <button class="btn-naranja text-center mt-5" routerLink="/conocete">¡No esperes más!</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-articulos">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center pb-4">
                <h3 class="titulo-1">Aprende más</h3>
            </div>
            <!--
            <div *ngFor="let nota of notas" class="col-md-4 ">
                <div class="row articulo-item">
                   
                    <div class="col-md-12 col-5">
                        
                        <img src="../{{ nota.thumb }} " class="img-fluid">
                    </div>
                   
                    <div class="articulo-item-text col-md-12 col-7">
                        <h4 class="titulo-3 pt-1">{{ nota.titulo }}</h4>
                        <small>{{ nota.categoria }}</small>
                        <p> {{ nota.intro }} </p>
                        <button class="btn-main btn-uno" (click)="getNotaUrl(nota)">Ver nota</button>
                    </div>
                </div>
            </div>
            -->
     
            <div class="col-12">
                <swiper [config]="config" (swiper)="automatico($event)" >
                    <ng-template swiperSlide *ngFor="let nota of notas">
                        <div class="nota-home-content" (click)="getNotaUrl(nota)" [style.background]="'url(../'+ nota.thumb +')'">
                            <div class="nota-gradient"></div>
                            <div class="nota-home">
                                <h4>{{ nota.titulo }}</h4>
                                <p> {{ nota.intro }} </p>
                            </div>
                        </div>
                    </ng-template>
                </swiper>

                <div routerLink="/aprende-mas" class="btn-naranja text-center mt-5" >Más información</div>
            </div>


        </div>
    </div>
</section>
<section class="section-drSimi">
    <div class="row">
        <div class="col-lg-12">
            <img class="frase" src="assets/images/frase.svg">
        </div>
        
    </div>
</section>
<!-- <section class="section-drSimi">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 ">
                <img class="frase" src="assets/images/frase.svg">
            </div>
            
            <div class="col-lg-6">
                <div class="preguntas-frecuentes-home">
                    <h3 class="titulo-1 text-light text-center">preguntas frecuentes</h3>
                    <p>Todas las preguntas principales relacionadas con la consulta, servicio y mucho más.</p>
                    <accordion [closeOthers]="oneAtATime">
                        <accordion-group heading="¿Su servicio es confidencial?">
                            <p>Sí; al inicio de la sesión te damos a conocer nuestro aviso de privacidad lo cual te brinda la seguridad de que tus datos personales estarán protegidos; puedes consultarlo en la siguiente liga: https://simisae.com.mx/aviso-de-privacidad</p>
                            <p>Aunado a ello, solo pediremos tu nombre y edad para tener información que nos ayude a la orientación psicológica, es importante mencionar que todos nuestros psicólogos son profesionales y la secrecía es parte de nuestra ética.</p>                        
                        </accordion-group>
                        <accordion-group heading="¿El servicio de ayuda psicológica tiene algún costo?">
                            <p>No, gracias a la visión altruista de nuestro fundador, el C.P. Victor González Torres (Dr. Simi), el servicio que ofrece SIMISAE es completamente gratuito.</p>
                        </accordion-group>
                        <accordion-group heading="¿Dónde puedo encontrarlos?">
                            <p>Puedes encontrarnos en los siguientes canales de atención:</p>
                            <p>Vía telefónica y WhatsApp a nuestro número 800 911 3232</p>
                            <p>Por medio de nuestro correo electrónico <strong>centrodiagnostico@simisae.com.mx </strong></p>
                            <p>Enviándonos un mensaje a nuestro Facebook o Instagram <strong>https://www.facebook.com/SIMISAE </strong>y <strong>@simisaemx</strong></p>
                            <p>Si prefieres ser atendido de forma presencial, ponemos a tu alcance el contacto de</p>
                        </accordion-group>
                        <accordion-group heading="¿Quién puede solicitar ayuda?">
                            <p>Todo aquel que necesite el apoyo psicológico, la persona que esté atravesando por un duelo, problemas para relacionarse, adicciones, depresión, ansiedad o ideación suicida, en fin, cualquier situación que esté afectando emocionalmente a la persona.</p>
                        </accordion-group>
                        
                    </accordion>
                    <br>
                    
                    <a routerLink="/preguntas-frecuentes" class="btn-naranja">Ver Más</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div *ngIf="showBotton" class="show-chat">
    <div class="bg-black"></div>
    <div class="flecha-chat">
        <div class="indicador-chat">
        </div>
    </div>

</div>
<!--
<div class="seudomodal" *ngIf="edited" >
    <div class="modaltest">
        <div>
            <button type="button" class="destroy" (click)="close()">
                <span >&times;</span>
            </button>
        </div>
        <div class="col-12 mx-auto text-center imgcont"> 
            <picture>
                <source type="image/webp" srcset="assets/images/banner-nuevo-sitio.webp">
                <source type="image/jpeg" srcset="assets/images/banner-nuevo-sitio.jpg">
                <img src="assets/images/banner-nuevo-sitio.jpg" alt="SIMISAE" (click)="gopoup()" class="img-fluid  popup">
            </picture>
        </div>
    </div>      
</div>
-->