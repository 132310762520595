import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { WebinarsService } from 'src/app/services/webinars.service';
import { NgForm } from '@angular/forms';
import { SimisaeService } from "src/app/services/simisae.service";
import { text } from 'express';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent implements OnInit {

  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;
  webinars: any;
  loading: boolean | undefined
  success: boolean | undefined
  showBotton: boolean | undefined
  webinarSend: any;

  constructor(
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private webinarService: WebinarsService,
    private _seoService: SeoService,
    public meta: Meta,
    public title: Title,
    private _simiSAE: SimisaeService,
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    })
    this.webinarService.getWebinars().subscribe((resp: any) => {
      this.webinars = resp
    })
   }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}

  ngOnInit(): void {
  }

addWebinar(f: NgForm) {

		this.loading = true
    const temaSeleccionado = f.value.selectEvent;

    // Busca el tema seleccionado en el arreglo JSON
    const webinarSeleccionado = this.webinars.find(webinar => webinar.tema === temaSeleccionado);
    const hora = webinarSeleccionado.hora;
    const fecha = webinarSeleccionado.fecha;
    const img= webinarSeleccionado.image;
    const url=webinarSeleccionado.webex;
    
		this.webinarSend = {
				evento: f.value.selectEvent,
        nombre: f.value.nombre,
        lugardeResidencia: f.value.lugar,
        email: f.value.email,
        edad: f.value.edad,
        hora:hora,
        fecha:fecha,
        imagen:img,
        url:url
			}
		
		this._simiSAE.postWebinar(this.webinarSend).subscribe((resp: any) => {
			if(resp.ok == false){
			  this.success = true
			  this.loading = false 
        f.reset()
			}else{
			  this.success = true
			  this.loading = false
        f.reset()
        setTimeout(()=>{                           
          this.success = false;
        }, 8000);
			}
		   
		});
    console.log(this.webinarSend)
	}
}
