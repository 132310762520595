<div class="container container-full">
    <div class="row"> 
        <div class="col-12 mx-auto text-center pt-5">
            <div class="col-12 text-left mb-3">
                <h2>Test sin canalizar <button class="btn btn-primary"  style="float:right" routerLink="/administrador/gestor" >
                    <span > ❮ Regresar</span>
                </button></h2>
            </div>
            <div class="table-responsive"> 
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th>Test</th>
                            <th>Nombre</th>
                            <th>Status</th>
                            <th>Revisar</th>
                        </tr>
                    </thead>
                    <tbody >
                    
                        <tr class="hover" *ngFor="let test of tests | paginate: {itemsPerPage: 10, currentPage: pageActual}">
                            <td >{{test.nombreTest}}</td>
                            <td >{{test.nombreCompleto}}</td>
                            <td > <span class="bg-warning"></span></td>
                            <td > <svg (click)="getDetalle(test)" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path></svg></td>
                        </tr>
                       
                    </tbody>
                </table>
                 <div class="col-12 text-center" style="display: flex;">
                    <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true" style="margin: auto;"> </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>