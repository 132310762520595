import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component'
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component'
import { ContactoComponent } from './pages/contacto/contacto.component'
import { ConsejosDeDrSimiComponent } from './pages/consejos-de-dr-simi/consejos-de-dr-simi.component'
import { InformateComponent } from './pages/informate/informate.component'
import { ProgramacionComponent } from './pages/programacion/programacion.component'
import { TestComponent } from './pages/test/test.component'
import { TestsComponent } from './pages/tests/tests.component'
import { ArticuloComponent } from './pages/articulo/articulo.component'
import { AvisoLegalComponent } from './pages/aviso-legal/aviso-legal.component'
import { AvisoDePrivacidadComponent } from './pages/aviso-de-privacidad/aviso-de-privacidad.component'
import { LoginComponent } from './pages/Administrador/login/login.component';
import { DashboardComponent } from './pages/Administrador/dashboard/dashboard.component';
import { TestdetalleComponent } from './pages/Administrador/testdetalle/testdetalle.component';
import { TestlistadoComponent } from './pages/Administrador/testlistado/testlistado.component';
import { VotosComponent } from './pages/Administrador/votos/votos.component';
import { TestshistoricoComponent } from './pages/Administrador/testshistorico/testshistorico.component';
import { Inicio2Component } from './pages/inicio2/inicio2.component';
import { WebinarsComponent } from './pages/webinars/webinars.component';
import { TalleresComponent } from './pages/Administrador/talleres/talleres.component';
import { SIMIAPSEComponent } from './pages/ubicaciones/simiapse/simiapse.component';
import { TalleresInscripcionComponent } from './pages/talleres-inscripcion/talleres-inscripcion.component';
import { CancelaInscripcionComponent } from './pages/cancela-inscripcion/cancela-inscripcion.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';

const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'quienes-somos', component: QuienesSomosComponent },
  { path: 'psicologos-en-linea', component: ContactoComponent },
  { path: 'consejos-del-dr-simi', component: ConsejosDeDrSimiComponent },
  { path: 'aprende-mas', component: InformateComponent },
  { path: 'programacion', component: ProgramacionComponent },
  { path: 'conocete', component: TestsComponent },
  { path: 'tests/:test', component: TestComponent },
  { path: 'aviso-legal', component: AvisoLegalComponent },
  { path: 'aviso-de-privacidad', component: AvisoDePrivacidadComponent },
  { path: 'articulo/:id/:titulo', component: ArticuloComponent },
  { path: 'administrador/login', component: LoginComponent },
  { path: 'administrador/gestor', component: DashboardComponent },
  { path: 'administrador/testsporasignar', component: TestlistadoComponent },
  { path: 'administrador/testdetalle/:id', component: TestdetalleComponent },
  { path: 'administrador/votos', component: VotosComponent },
  { path: 'administrador/talleres', component: TalleresComponent},
  { path: 'administrador/testhistorico', component: TestshistoricoComponent },
  { path: 'iniciowebinar', component: Inicio2Component },
  { path: 'talleres', component: WebinarsComponent},
  { path: 'SIMIAPSE', component: SIMIAPSEComponent },
  { path: 'cancela-inscripcion', component: CancelaInscripcionComponent },
  { path: 'preguntas-frecuentes', component: PreguntasFrecuentesComponent },
  //{ path: 'talleres-inscripcion', component: TalleresInscripcionComponent },
  { path: '**', pathMatch: 'full', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    preloadingStrategy: NoPreloading
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
