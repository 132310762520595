import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { WebinarsService } from 'src/app/services/webinars.service';
import { SimisaeService } from 'src/app/services/simisae.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-talleres',
  templateUrl: './talleres.component.html',
  styleUrls: ['./talleres.component.css']
})
export class TalleresComponent implements OnInit {

  tests!: any[];
  pageActual: number = 1;
  show = false;
  webinars: any;
 


  constructor(private _simiSAE: SimisaeService,private router: Router,private webinarService: WebinarsService,private datePipe: DatePipe) { 
    if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
      this.router.navigate(['/administrador/login']);
    }
    this.webinarService.getWebinars1().subscribe((resp: any) => {
      this.webinars = resp
    })
  }
  
  formatearFecha(fecha: string): string {
    return this.datePipe.transform(fecha, 'yyyy-MM-dd');
  }



  ngOnInit(): void {
   
  }
  onSubmit(form: NgForm) {
      
      this._simiSAE.GetWebinars( form.value.selectEvent).subscribe((resp:any) => {
        console.log(form.value.selectEvent)
       this.show=true;
        console.log(resp);
        this.tests = resp.reverse();

        
          this.tests.forEach(test => {
            // Verifica que test tenga la propiedad 'status' antes de modificarla
            if (test && (test.status == true || test.status == null)) {
              test.status = 'Registrado';
            } else {
              test.status = 'Cancelado';
            }

            if (test.fecreg) {
              test.fecreg = this.formatearFecha(test.fecreg);
            }


          
          });
        
      
        
      })
  }
  name = 'Registros-taller- .xlsx';
  exportToExcel(): void {
    let element = document.getElementById('season-tble');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }
  

  

}
