import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UbicacionesService {

  constructor(private http: HttpClient) { }

  getEstados() {
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/`);
  }

  getEstadosSIMIAPSE() {
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/estadoSIMIAPSE`);
    // return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/estadodental`);

  }

  getCiudades(id:any){
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/${id}`);
    // return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/${id}`);

  }

  getCiudadesSIMIAPSE(id:any){
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/municipioSIMIAPSE?id=${id}`);
    // return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/municipiodental?id=${id}`);
  }

  getSucursalesSIMIAPSE(id:any){
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultorioSIMIAPSE?id=${id}`);
    // return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultoriodental?id=${id}`);

  }

  getPosicionSIMIAPSE(latitudup:any, latituddow:any, longitudup:any, longituddow:any){
    return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultorioSIMIAPSEposition?latitudup=${latitudup}&latituddow=${latituddow}&longitudup=${longitudup}&longituddow=${longituddow}`);
    // return this.http.get(`https://fundacionbest.org.mx/Webapi/best/api/ubicacion/consultoriodentalposition?latitudup=${latitudup}&latituddow=${latituddow}&longitudup=${longitudup}&longituddow=${longituddow}`);
  }
}
