import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms'
import { ActivatedRoute, NavigationEnd } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { EvaluacionService } from '../../services/evaluacion.service'
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InformateService } from "src/app/services/informate.service";
import { SimisaeService } from "src/app/services/simisae.service";
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { filter } from 'rxjs/operators';

export interface Question {
  question: string
  response: string
}


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})


export class TestComponent implements OnInit {
  private subscription: Subscription = new Subscription()
  element: HTMLElement | undefined;
  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;

  test: any
  testName: string | undefined
  testDescripcion: string | undefined
  questions: any
  response: string | undefined
  modalRef: BsModalRef | undefined
  isCollapsed = true
  items: Observable<any[]> | undefined
  comentarios: string | undefined
  nombre: string | undefined
  correo: string | undefined
  unrespondedQuestions: number[] = [];
  loading = false
  success = false
  isSecondary = false
  resultadosTest: any;
  

  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  isModalShown = false
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private evaluacionService: EvaluacionService,
    private _simiSAE: SimisaeService,
    private _urlcanonicaservice: UrlcanonicaService,
    private _seoService: SeoService,
    private informateService: InformateService,
    public meta: Meta,
    public title: Title
  ) { 
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    });
    this.subscription.add(
      this.activatedRoute.params.subscribe(param => {
          this.informateService.getTest().subscribe((resp: any) => {
              this.test = resp.find((test: any) => test.url === param.test)
              this.testName = this.test.name
              this.testDescripcion = this.test.descripcion
              this.questions = this.test.questions
              if (this.test.isSecondary) {
                this.isSecondary = true
              }
          })
      })
    )
  }


  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url', content: this.urlcanonicaseo })
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}


  ngOnInit(): void {
  }


  getResult(f: NgForm) {
    this.unrespondedQuestions = []
    this.questions.forEach((question: Question, index: number) => {
      const response = f.form.value[question.question]
      if (response === '') {
        this.unrespondedQuestions.push(index + 1);
      }
      this.questions[index].response = response
    })

    if (this.unrespondedQuestions.length <= 0) {
      this.response = this.evaluacionService.selectEvaluacion( this.test.name, this.questions )
      this.showModal()
    }
  }

  sendAndShowEval() {
    this.loading = true
    this.resultadosTest = {
      NombreTest: this.testName,
      Comentarios: this.comentarios,
      NombreCompleto: this.nombre,
      Correo: this.correo,
      RespuestaTest: this.response,
    }

    this.questions.forEach((question: Question, index: number) => {
      index++
      this.resultadosTest[`Pregunta${index}`] = question.question;
      this.resultadosTest[`Respuesta${index}`] = question.response;
    })

    this._simiSAE.postnuevapostulacion(this.resultadosTest, this.questions.length).subscribe((resp: any) => {

        if(resp.ok == false){
          this.success = true
          this.loading = false  
          this.response = resp.message
        }else{
          this.success = true
          this.loading = false  
        }
       
    });
  }

  showModal(): void {
    this.isModalShown = true;
  }
  
  hideModal(): void {
      this.autoShownModal?.hide();
  }

  onClose(): void {
    if(this.success == false){
      this.onHidden();
    }else{
      this.router.navigate(['/tests']);
    }
  }

  onHidden(): void {
    this.isModalShown = false
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
